<template>
  <DictSelect
    :value="value"
    :options="options"
    @select="select"
    :placeholder="placeholder"
    :clearable="clearable"
    :filterable="filterable"
    :disabled="disabled"
  />
</template>

<script>
import DictSelect from '@/components/Select/DictSelect'
import { select } from '@/config'

export default {
  components: {DictSelect},
  props: {
    value: {required: true},
    config: {type: String, required: true},
    placeholder: {type: String, default: '请选择'},
    clearable: {type: Boolean, default: false},
    filterable: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    options() {
      if (this.config in select) {
        return select[this.config]
      }
      return {}
    }
  },
  methods: {
    select(data) {
      this.$emit('input', data)
    }
  }
}
</script>

<style>

</style>