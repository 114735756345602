import { getIndexByKeyValue } from "@/helper";

export default class BaseStore {
  constructor(api, model, namespaced = true) {
    this.api = new api(model);
    this.model = model;
    this.namespaced = namespaced;
  }

  state() {
    return {
      pagination: { items: [], page_count: 0, total: 0 },
      all: [],
      params: { size: 10, page: 1, filter: {}, keywords: {} },
      batch: [], // 要批量修改的
    };
  }

  getters() {
    return {};
  }

  actions() {
    const api = this.api;
    const model = this.model;
    return {
      async create(state, { data }) {
        return await api.create(data);
      },
      async update({ commit }, { uid, patch }) {
        const res = await api.update(uid, patch);
        if (model != "agentfee") {
          commit("updateState", { uid, patch: res.data });
        }
        return res;
      },
      async batch_update({ dispatch }, data) {
        const res = await api.batch_update(data);
        if (res.data) dispatch("pagination", null);
        return res;
      },
      async get(state, uid) {
        return await api.get(uid);
      },
      async delete({ commit }, uid) {
        const res = await api.delete(uid);
        if (res.status === 204) {
          commit("deleteItem", uid);
          return true;
        }
        return false;
      },
      async all({ commit }, params = null) {
        const res = await api.all(params);
        commit("setAll", res.data);
      },
      async search(state, { field, keyword }) {
        return await api.search(field, keyword);
      },
      async pagination({ state, commit }, data = null) {
        const params = data != null ? data : state.params;
        const res = await api.pagination(params);
        commit("setPagination", res.data);
      },
      async docx(state, data) {
        api.docx(data);
      },
      async csv(state, { data, csv_name }) {
        api.csv(data, csv_name);
      },
      // async pagination({ commit }, data) {
      //   const res = await api.pagination(data)
      //   commit('setPagination', res.data)
      // }
    };
  }

  mutations() {
    return {
      setAll(state, data) {
        state.all = data;
      },
      setPagination(state, data) {
        state.pagination = data;
      },
      updateParams(state, params) {
        state.params = params;
      },
      updateState(state, { uid, patch }) {
        let index = getIndexByKeyValue(state.pagination.items, "uid", uid);
        state.pagination.items.splice(index, 1, patch);
        index = getIndexByKeyValue(state.all, "uid", uid);
        state.all.splice(index, 1, patch);
      },
      deleteItem(state, uid) {
        let index = getIndexByKeyValue(state.pagination.items, "uid", uid);
        if (index >= 0) {
          state.pagination.items.splice(index, 1);
          state.pagination.total -= 1;
        }
        index = getIndexByKeyValue(state.all, "uid", uid);
        state.all.splice(index, 1);
      },
      setBatch(state, array) {
        state.batch = array;
      },
      clearBatch(state) {
        state.patch = [];
      },
    };
  }

  toStore() {
    return {
      namespaced: this.namespaced,
      state: this.state(),
      getters: this.getters(),
      actions: this.actions(),
      mutations: this.mutations(),
    };
  }
}
