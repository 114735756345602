<template>
  <MTableBoard title="报销单" storeName="reimbursement" searchField="item"
    :permisson="1" :toolbarPermisson="1" :onlyMe="true">
    <template v-slot:other-tool>
      <ReimbursementFilter />
    </template>

    <template v-slot:table>
      <ReimbursementTable :border="false" />
    </template>
  </MTableBoard>
</template>

<script>
import ReimbursementTable from "./ReimbursementTable";
import ReimbursementFilter from "./ReimbursementFilter";
import MTableBoard from "@/components/MTableBoard";

export default {
  name: "ReimbursementBoard",
  components: { ReimbursementTable, ReimbursementFilter, MTableBoard }
};
</script>