<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="负责人"
    />
    <ModelSelect
      v-model="params.filter.consumer"
      @input="tableBoard.filter"
      :clearable="true"
      model="consumer"
      class="pl-1 w-24"
      placeholder="所有人"
    />
    <ConfigSelect
      v-model="params.filter.category"
      @input="tableBoard.filter"
      :clearable="true"
      config="companyCategories"
      class="pl-1 w-24"
      placeholder="类型"
    />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "CompanyFilter",
  mixins: [filterMixin("agentfee")],
  components: { ModelSelect, ConfigSelect }
};
</script>

<style>
</style>