<template>
  <m-form
    v-if="me.permisson >= permisson || me.uid === formData.user"
    :title="`${mode}任务信息`"
    class="bg-white"
  >
    <template v-slot:form>
      <!-- <ServiceDetail v-if="formData.service" :uid="formData.service" class="border rounded my-2" /> -->

      <el-form label-position="top" label-width="80px" :model="formData">
        <div class="flex w-full">
          <el-form-item label="公司选择" class="w-1/2 pr-1">
            <ModelSelect
              :disabled="me.permisson < 100"
              v-model="formData.company"
              model="company"
            />
          </el-form-item>

          <el-form-item label="办事人选择" class="w-1/2 pl-1">
            <ModelSelect
              :disabled="me.permisson < 100"
              v-model="formData.user"
              model="user"
            />
          </el-form-item>
        </div>

        <el-form-item v-if="me.permisson >= 100" label="业务选择">
          <ServiceSelect
            v-model="formData.service"
            :serviceCategory="serviceCategory"
            @changeCategory="changeCategory"
          />
        </el-form-item>

        <div class="flex w-full">
          <el-form-item label="状态" class="w-1/2 pr-1">
            <ConfigSelect v-model="formData.status" config="taskStatuses" />
          </el-form-item>

          <el-form-item
            v-if="me.permisson >= 100"
            label="预付款(元，请输入整数)"
            class="w-1/2 pl-1"
          >
            <el-input v-model="formData.advance" type="number"></el-input>
          </el-form-item>
        </div>

        <div class="flex w-full">
          <el-form-item
            v-if="me.permisson >= 100"
            label="收费调节(元，请输入整数)"
            class="w-1/2 pr-1"
          >
            <el-input v-model="formData.offset" type="number"></el-input>
          </el-form-item>

          <el-form-item
            v-if="me.permisson >= 100"
            label="奖金调节(元，请输入整数)"
            class="w-1/2 pl-1"
          >
            <el-input v-model="formData.bonus_offset" type="number"></el-input>
          </el-form-item>
        </div>

        <div class="flex flex-col w-full my-2 bg-gray-100 p-4">
          <div class="flex justify-between items-center">
            <span class="text-indigo-400"
              >垫付费用明细 (记得点最底下的【修改】进行保存)</span
            >
            <el-button @click="addTaskFee">添加</el-button>
          </div>
          <TaskFeeCell v-model="formData.task_fee" class="my-2" />
          <div class="text-right">
            <span class="px-3 text-indigo-400 text-sm"
              >总垫付(元)： {{ formData.cost }}</span
            >
          </div>
        </div>

        <el-form-item label="备注(可多行)">
          <textarea
            v-model="formData.note"
            class="w-full p-2 font-sm leading-tight border text-gray-600 outline-none"
            rows="3"
          ></textarea>
        </el-form-item>

        <MAttachment v-model="formData.attachments" />

        <el-form-item class="py-4">
          <el-button @click="submit">{{ mode }}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import MAttachment from "@/components/MAttachment";
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import ServiceSelect from "@/components/Service/ServiceSelect";
// import ServiceDetail from "@/components/Service/ServiceDetail";
import TaskFeeCell from "./TaskFeeCell";

export default {
  mixins: [formMixin("task")],
  components: {
    MForm,
    MAttachment,
    ConfigSelect,
    ServiceSelect,
    ModelSelect,
    // ServiceDetail,
    TaskFeeCell,
  },
  data() {
    return {
      permisson: 10,
      serviceCategory: null,
      formData: {
        status: 0,
        cost: 0,
        // task_fee: [{item: '', price: 0, account: 1, total: 0}],
        task_fee: null,
        advance: 0, // 客户预付款
        offset: 0, // 收费调节
        bonus_offset: 0, // 奖金调节
        note: "",
        attachments: [],
        service: null,
        user: null,
        company: null,
      },
    };
  },
  watch: {
    ["formData.task_fee"]() {
      if (this.formData.task_fee !== null) {
        this.formData.cost = this.formData.task_fee
          .map((i) => i.total)
          .reduce((a, b) => a + b, 0);
      }
    },
  },
  methods: {
    mountResponse(res) {
      this.formData = {
        ...res.data,
        company: res.data.company.uid,
        service: res.data.service.uid,
        user: res.data.user.uid,
      };
      this.serviceCategory = res.data.service.category;
    },
    verify(data) {
      return (
        data.user &&
        data.service &&
        data.company &&
        data.offset.toString() &&
        data.bonus_offset.toString() &&
        data.cost.toString()
      );
    },
    changeCategory(category) {
      this.serviceCategory = category;
    },
    addTaskFee() {
      const fee = { item: "", price: 0, account: 1, total: 0 };
      if (this.formData.task_fee === null) {
        this.formData.task_fee = [fee];
      } else {
        this.formData.task_fee.push(fee);
      }
    },
  },
};
</script>
