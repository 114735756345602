<template>
  <m-form
    v-if="me.permisson >= permisson"
    :title="`${mode}代理收费`"
    class="bg-white"
  >
    <template v-slot:form>
      <div v-if="other" class="py-2 text-indigo-500 text-lg">
        {{other.company.name}} |
        {{other.consumer.name}} |
        {{other.year}}年|
        {{`${other.company.agent_fee}/${cycle(other.company.cycle_months)}`}}
      </div>

      <el-form label-position="top" label-width="80px" :model="formData">

        <el-form-item label="备注">
          <el-input v-model="formData.note" type="textarea" :rows="3"/>
        </el-form-item>

        <MAttachment v-model="formData.attachments"/>

        <el-form-item>
          <el-button @click="submit">{{mode}}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import MAttachment from "@/components/MAttachment";
import mixin from './mixin'

export default {
  mixins: [formMixin("agentfee"), mixin],
  components: { MForm, MAttachment,},
  data() {
    return {
      permisson: 100,
      formData: {
        note: "",
        attachments: [],
      },
      other: null
    };
  },
  methods: {
    mountResponse(res) {
      this.other = {
        ...res.data,
      }
      this.formData.note = res.data.note
      this.formData.attachments = res.data.attachments
    },
    verify() {
      return true
    }
  }
};
</script>