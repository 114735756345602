<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}交接单`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">
        <div class="mt-4 text-gray-600">清单内容(至少一行)</div>
        <TableField v-model="formData.items" :columns="columns" class="py-4" />

        <div class="flex w-full">
          <el-form-item label="移交公司(必填)" class="w-1/2">
            <ModelSelect v-model="formData.company" model="company" />
          </el-form-item>

          <el-form-item label="交接日期(必填)" class="w-1/2 pl-2">
            <el-date-picker
              v-model="formData.date"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </div>

        <el-form-item label="备注(非必填，可多行)">
          <textarea v-model="formData.note" class="w-full p-2 font-sm leading-tight border text-gray-600 outline-none"  rows="3"></textarea>
        </el-form-item>

        <div v-if="!isEdit || hasEditPermisson">
          <MAttachment v-model="formData.attachments" />
        </div>

        <el-form-item>
          <button
            @click.prevent="submit"
            class="text-gray-200 bg-indigo-700 px-6 rounded-lg shadow"
          >{{mode}}</button>
          <button
            @click.prevent="docx"
            class="mx-2 text-gray-200 bg-indigo-700 px-6 rounded-lg shadow"
          >导出docx</button>
          <span v-if="isEdit && !hasEditPermisson" class="px-3 text-pink-400">没有修改权限</span>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import MAttachment from "@/components/MAttachment";
import TableField from "@/components/TableField";
import ModelSelect from "@/components/Select/ModelSelect";

export default {
  mixins: [formMixin("receipt")],
  components: { MForm, MAttachment, TableField, ModelSelect },
  data() {
    return {
      permisson: 1,
      columns: [
        { name: "item", label: "移交项" },
        { name: "account", label: "数量", type: "number" },
        { name: "note", label: "备注" }
      ],
      formData: {
        date: null,
        items: [],
        note: "",
        attachments: [],
        company: null,
        user: null
      }
    };
  },
  computed: {
    hasEditPermisson() {
      return this.me.permisson >= 100 || this.formData.user === this.me.uid;
    }
  },
  mounted() {
    if (this.$store.state.company.all.length < 1) {
      this.$store.dispatch(`company/all`);
    }
  },
  methods: {
    packData() {
      let data = { ...this.formData };
      if (!this.isEdit) {
        data["user"] = this.me.uid;
      }
      return data;
    },
    verify(data) {
      return data.items.length > 0 && data.company && data.date
      
    },
    async docx() {
      if (!(this.formData.items.length > 0 && this.formData.company)) {
        this.$notify.error({ message: "清单内容为空或者公司未选择" });
        return;
      }
      // 这是uid
      const company = this.formData.company;
      const items = this.formData.items;
      const file_name = `交接单_${company}.docx`;
      this.$store.dispatch("receipt/docx", { company, items, file_name });
    }
  }
};
</script>