<template>
  <div>
    <div class="flex justify-between py-2">
      <div></div>
      <div>
        <span
          v-for="tool in tools"
          v-show="me.permisson >= tool.permisson"
          :key="tool.label"
          @click="tool.do"
          class="px-1 text-sm text-indigo-500 cursor-pointer"
          >{{ tool.label }}</span
        >
      </div>
    </div>

    <el-table
      :data="tableData"
      :border="border"
      :v-loading="true"
      @selection-change="select"
    >
      <el-table-column type="selection" :width="25"></el-table-column>

      <el-table-column prop="item" label="报销事项">
        <template slot-scope="scope">
          <span class="flex items-center cursor-pointer">
            <span class="pr-1 text-indigo-400">{{ scope.$index + 1 }}</span>
            <el-image
              v-if="
                scope.row.attachments &&
                  is_image(scope.row.attachments).length > 0
              "
              style="width: 20px; height: 20px; margin-right: 5px"
              :src="attachments2urls(is_image(scope.row.attachments))[0]"
              :preview-src-list="
                attachments2urls(is_image(scope.row.attachments))
              "
            ></el-image>
            <span @click="edit(scope.row)">{{ scope.row.item }}</span>
            <!-- <span v-else>{{scope.row.items.map(i => i.item).join(',')}}</span> -->
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="user" label="员工">
        <template slot-scope="scope">
          <span
            @click="filterCurrent('user', scope.row.user.uid)"
            class="cursor-pointer"
            >{{ scope.row.user.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column prop="date" label="日期" width="80">
        <template slot-scope="scope">{{ scope.row.date | date }}</template>
      </el-table-column>

      <el-table-column prop="money" label="金额" width="60" align="right" />

      <el-table-column prop="approval" label="批准" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.approval"
            :disabled="me.permisson < 100"
            @change="change(scope.row, 'approval')"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="paid" label="支付" width="80">
        <template slot-scope="scope">
          <el-switch
            v-if="!scope.row.paid"
            :width="30"
            :disabled="me.permisson < 100"
            :active-text="scope.row.paid ? '已付' : '未付'"
            v-model="scope.row.paid"
            @change="changePaidStatus(scope.row, 'paid')"
          ></el-switch>
          <span v-else>{{
            scope.row.paid_note ? scope.row.paid_note : "已付"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="note" label="备注" />

      <el-table-column prop="attachments" label="附件">
        <template slot-scope="scope">
          <el-link
            v-for="atm in is_other(scope.row.attachments)"
            :key="atm.target"
            :href="targetToUrl(atm.target)"
            :download="atm.name"
            >{{ atm.source }}</el-link
          >
        </template>
      </el-table-column>

      <el-table-column width="40">
        <template slot-scope="scope">
          <DeleteButton v-if="!scope.row.paid && (me.permisson >= 100 || me.uid === scope.row.user.uid)" :delete="deleteItem" :uid="scope.row.uid" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { filters, arrayToCSV } from "@/helper";
import { tableMixin } from "@/mixins/table";
import attachmentMixin from "@/mixins/attachment";
import DeleteButton from "@/components/DeleteButton";

export default {
  mixins: [tableMixin("reimbursement"), attachmentMixin],
  components: { DeleteButton },
  data() {
    return {
      tools: [
        { permisson: 100, do: () => this.batchTrue("approval"), label: "批准" },
        { permisson: 1, do: () => this.exportCSV(), label: "导出清单" },
      ],
    };
  },
  filters: {
    ...filters,
  },
  mounted() {
    this.$store.dispatch(`user/all`);
  },
  methods: {
    exportCSV() {
      this.verifySelected();
      let data = this.selected.map(function(i) {
        return [
          i.item,
          i.user.name,
          filters.date(i.date),
          i.money,
          i.note,
          i.approval,
          i.paid,
          i.paid_note
        ];
      });
      data = [
        ["报销事项", "申请人", "日期", "金额", "备注", "是否批准", "是否支付", "支付备注"],
        ...data,
      ];
      arrayToCSV(data, "报销清单");
    }
  }
};
</script>
