<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="px-1 w-24"
      placeholder="员工"
    />
    <MDatePicker @picked="tableBoard.selectRange" />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import MDatePicker from "@/components/MDatePicker";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "ReimbursementFilter",
  mixins: [filterMixin("reimbursement")],
  components: { ModelSelect, MDatePicker }
};
</script>