import axios from "axios";
import { arrayToCSV } from "@/helper";

// export const baseUrl = "http://127.0.0.1:5000/";
export const baseUrl = "http://oa_api.xindacai.com/";
export const uploadUrl = `${baseUrl}common/upload`;
export const smsUrl = `${baseUrl}common/sms`;

export async function local(func, params = {}) {
  return axios.post(`http://127.0.0.1:5001/${func}`, params);
}

export async function upload(file) {
  let form = new FormData();
  form.append("file", file);
  const headers = { "Content-Type": "multipart/form-data" };
  return await axios.post(uploadUrl, form, { headers });
  // const token = localStorage.getItem("token");
  // // 如果是fetch一定不要设定 Content-Type，让浏览器自己决定，不然会出错的
  // const headers = { Token: token };
  // const resp = await fetch(uploadUrl, { headers, body: form, method: "POST" });
  // return await resp.json();
}

export async function send_sms(temp_id, telphone_list, params_list) {
  // 返回发送失败的列表
  return await axios.post(smsUrl, { temp_id, telphone_list, params_list });
}

export class Api {
  constructor(model) {
    this.model = model;
    this.url = `${baseUrl}${model}`;
  }
  async get(uid) {
    const url = `${this.url}/${uid}`;
    return await axios.get(url);
  }

  async create(data) {
    return await axios.post(this.url, data);
  }

  async update(uid, patch) {
    const url = `${this.url}/${uid}`;
    return await axios.put(url, patch);
  }

  async batch_update(data) {
    const url = `${this.url}/batch/update`;
    return await axios.put(url, data);
  }

  async delete(uid) {
    const url = `${this.url}/${uid}`;
    return await axios.delete(url);
  }

  async all(params = null) {
    const url = new URL(`${this.url}/all`);
    if (params) {
      url.search = new URLSearchParams(params);
    }
    return await axios.get(url.toString());
  }

  async pagination(data) {
    const url = new URL(`${this.url}/pagination`);
    return await axios.post(url, data);
  }

  async search(field, keyword) {
    const url = `${this.url}/search?field=${field}&keyword=${keyword}`;
    return await axios.get(url);
  }

  async docx(data) {
    // 在服务器端生成docx
    const url = new URL(`${this.url}/docx`);
    const file_name = data.file_name || "download.docx";
    axios.post(url, data, { responseType: "blob" }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    });
  }

  async csv(data = {}, csv_name = "download") {
    // 服务器端把数据整理好，返回json格式数据，本地生成csv
    const url = new URL(`${this.url}/csv`);
    axios.post(url, data).then((res) => {
      arrayToCSV(res.data, csv_name);
    });
  }
}

export class User extends Api {
  async login(name, password) {
    const url = `${this.url}/login`;
    return await axios.post(url, { name, password });
  }
  async me() {
    const url = `${this.url}/me`;
    return await axios.get(url);
  }
}

export class Wage extends Api {
  async generate(year, month) {
    const url = `${this.url}/generate/${year}/${month}`;
    return await axios.get(url);
  }
  async pay(uids) {
    const url = `${this.url}/pay`;
    return await axios.post(url, uids);
  }
}
