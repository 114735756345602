<template>
  <div @click="onClick" class="flex justify-center items-center bg-white text-gray-600 px-3 py-2 shadow rounded-lg cursor-pointer hover:text-indigo-400">
    {{label}} 
  </div>
</template>

<script>

export default {
  name: "MButton",
  props: {label: {type: String, required: true}},
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
};
</script>