import Vue from 'vue'
import VueRouter from 'vue-router'

// import Index from './components/Index'
import NotPermisson from './components/NotPermisson'

import Login from './components/User/Login'
import ChangePassword from './components/User/ChangePassword'
import UserBoard from './components/User/UserBoard'
import UserForm from './components/User/UserForm'

import ConsumerBoard from './components/Consumer/ConsumerBoard'
import ConsumerForm from './components/Consumer/ConsumerForm'

import SMSTemplateBoard from './components/SMSTemplate/SMSTemplateBoard'
import SMSTemplateForm from './components/SMSTemplate/SMSTemplateForm'

import CompanyBoard from './components/Company/CompanyBoard'
import CompanyForm from './components/Company/CompanyForm'

import ReceiptBoard from './components/Receipt/ReceiptBoard'
import ReceiptForm from './components/Receipt/ReceiptForm'

import ReimbursementBoard from './components/Reimbursement/ReimbursementBoard'
import ReimbursementForm from './components/Reimbursement/ReimbursementForm'

import ServiceBoard from './components/Service/ServiceBoard'
import ServiceForm from './components/Service/ServiceForm'

import TaskBoard from './components/Task/TaskBoard'
import TaskForm from './components/Task/TaskForm'

import WageBoard from './components/Wage/WageBoard'
import WageForm from './components/Wage/WageForm'

import AgentFeeBoard from './components/AgentFee/AgentFeeBoard'
import AgentFeeForm from './components/AgentFee/AgentFeeForm'

import FJGS from './components/Local/FJGS'

import store from './store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: TaskBoard,
      meta: {permisson: 1}
    },
    {
      path: "/not_permisson",
      component: NotPermisson,
      meta: {permisson: -1}
    },
    {
      path: "/user/login",
      component: Login,
      meta: {permisson: 0}
    },
    {
      path: "/user/change_password",
      component: ChangePassword,
      meta: {permisson: 1}
    },
    {
      path: "/users",
      component: UserBoard,
      meta: {permisson: 10}
    },
    {
      path: "/user/:uid",
      component: UserForm,
      name: 'user-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/smstemplates",
      component: SMSTemplateBoard,
      meta: {permisson: 100}
    },
    {
      path: "/smstemplate/:uid",
      component: SMSTemplateForm,
      name: 'smstemplate-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/consumers",
      component: ConsumerBoard,
      meta: {permisson: 100}
    },
    {
      path: "/consumer/:uid",
      component: ConsumerForm,
      name: 'consumer-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/companys",
      component: CompanyBoard,
      meta: {permisson: 100}
    },
    {
      path: "/company/:uid",
      component: CompanyForm,
      name: 'company-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/receipts",
      component: ReceiptBoard,
      meta: {permisson: 100}
    },
    {
      path: "/receipt/:uid",
      component: ReceiptForm,
      name: 'receipt-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/reimbursements",
      component: ReimbursementBoard,
      meta: {permisson: 100}
    },
    {
      path: "/reimbursement/:uid",
      component: ReimbursementForm,
      name: 'reimbursement-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/services",
      component: ServiceBoard,
      meta: {permisson: 100}
    },
    {
      path: "/service/:uid",
      component: ServiceForm,
      name: 'service-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/tasks",
      component: TaskBoard,
      meta: {permisson: 100}
    },
    {
      path: "/task/:uid",
      component: TaskForm,
      name: 'task-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/wages",
      component: WageBoard,
      meta: {permisson: 100}
    },
    {
      path: "/wage/:uid",
      component: WageForm,
      name: 'wage-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/agent_fees",
      component: AgentFeeBoard,
      meta: {permisson: 100}
    },
    {
      path: "/agent_fee/:uid",
      component: AgentFeeForm,
      name: 'agentfee-form',
      props: true,
      meta: {permisson: 100}
    },
    {
      path: "/local/fjgs",
      component: FJGS,
      meta: {permisson: 1000}
    },
  ]
})


router.beforeEach((to, from, next) => {
  const token = store.state.user.me.token || localStorage.getItem('token')
  if (to.path === '/user/login' || store.state.user.me.name) {
    next()
  } else {
    if (token) {
      store.dispatch('user/getMe', token)
        .then(() => next())   
        .catch(() => next({path: '/user/login'}))
    } else {
      next({path: '/user/login'})
    }
  }
})

export default router