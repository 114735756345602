<template>
  <el-select
    @change="select"
    :value="value"
    :placeholder="placeholder"
    :clearable="clearable"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option v-for="(v, k) in options" :key="k" :label="v" :value="parseInt(k)" />
  </el-select>
</template>

<script>
export default {
  name: 'DictSelect',
  props: {
    value: {required: true},
    options: {required: true},
    placeholder: {type: String, default: '请选择'},
    clearable: {type: Boolean, default: false},
    filterable: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
  },
  methods: {
    select(option) {
      this.$emit('select', option)
    }
  }
}
</script>

<style>

</style>