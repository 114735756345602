<template>
  <div>
    <el-table :data="tableData" :border="border">
      <el-table-column type="index" width="40"></el-table-column>

      <el-table-column prop="name" label="业务名称">
        <template slot-scope="scope">
          <span class="flex items-center">
            <el-image
              v-if="scope.row.attachments && is_image(scope.row.attachments).length > 0"
              style="width: 20px; height: 20px; margin-right: 5px"
              :src="attachments2urls(is_image(scope.row.attachments))[0]"
              :preview-src-list="attachments2urls(is_image(scope.row.attachments))"
            ></el-image>
            <el-tooltip effect="dark" :content="scope.row.description" placement="right">
              <el-link :underline="false" @click="edit(scope.row)">{{scope.row.name}}</el-link>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="smstemplate" label="短信模板" width="120">
        <template slot-scope="scope">{{ scope.row.sms_template ? scope.row.sms_template.name : '' }}</template>
      </el-table-column>

      <el-table-column prop="category" label="类型" width="120">
        <template slot-scope="scope">
          <ConfigSelect
            v-model="scope.row.category"
            @input="change(scope.row, 'category')"
            config="serviceCategories"
          />
        </template>
      </el-table-column>

      <el-table-column prop="status" label="状态" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :disabled="me.permisson < 10"
            @change="change(scope.row, 'status')"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="收费/提成">
        <template slot-scope="scope">{{`${scope.row.charge}/${scope.row.bonus}`}}</template>
      </el-table-column>

      <el-table-column prop="days" label="办理天数" />
      <el-table-column prop="attachments" label="附件">
        <template slot-scope="scope">
          <el-link
            v-for="atm in is_other(scope.row.attachments)"
            :key="atm.target"
            :href="targetToUrl(atm.target)"
            :download="atm.name"
          >{{atm.source}}</el-link>
        </template>
      </el-table-column>

      <el-table-column width="40" v-if="me.permisson >= 1000">
        <template slot-scope="scope">
          <DeleteButton :delete="deleteItem" :uid="scope.row.uid" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { tableMixin } from "@/mixins/table";
import attachmentMixin from "@/mixins/attachment";
import ConfigSelect from "@/components/Select/ConfigSelect";
import DeleteButton from "@/components/DeleteButton";

export default {
  mixins: [tableMixin("service"), attachmentMixin],
  components: { ConfigSelect, DeleteButton },
  mounted() {
    this.$store.dispatch("smstemplate/all");
  }
};
</script>