import Vue from "vue";
import Axios from "axios";
import ElementUI from "element-ui";
import store from "./store";
import router from "./route";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/style.css";

Vue.config.productionTip = false;
Vue.use(ElementUI);

Axios.defaults.headers.common["Token"] = localStorage.getItem("token");

const app = new Vue({
  store,
  router,
  render: (h) => h(App),
});
app.$mount("#app");

export default app;
