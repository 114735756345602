<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}工资单`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">
        <div class="flex w-full">
          <el-form-item label="员工" class="w-1/5">
            <ModelSelect v-model="formData.user" model="user" />
          </el-form-item>
          <el-form-item label="年" class="w-1/5 px-1">
            <ConfigSelect v-model="formData.year" :clearable="false" config="years" placeholder="年" />
          </el-form-item>
          <el-form-item label="月" class="w-1/5 px-1">
            <ConfigSelect v-model="formData.month" :clearable="false" config="months" placeholder="月" />
          </el-form-item>
          <el-form-item label="出勤天数" class="w-1/5">
            <el-input type="number" v-model="formData.work_days" />
          </el-form-item>
          <el-form-item label="请假迟到等扣款" class="w-1/5">
            <el-input type="number" v-model="formData.deduction" />
          </el-form-item>
        </div>

        <div class="flex w-full">
          <el-form-item label="基本工资" class="w-1/5">
            <el-input type="number" v-model="formData.basic" />
          </el-form-item>
          <el-form-item label="补贴" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.merit_pay" />
          </el-form-item>
          <el-form-item label="工龄奖" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.year_pay" />
          </el-form-item>
          <el-form-item label="满勤奖" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.full_day_pay" />
          </el-form-item>
          <el-form-item label="代理提成" class="w-1/5">
            <el-input type="number" v-model="formData.agent_bonus" />
          </el-form-item>
        </div>

        <div class="flex w-full">
          <el-form-item label="开票提成" class="w-1/5">
            <el-input type="number" v-model="formData.invoice_bonus" />
          </el-form-item>
          <el-form-item label="任务提成" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.task_bonus" />
          </el-form-item>
          <el-form-item label="介绍提成" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.introduce_bonus" />
          </el-form-item>
          <el-form-item label="代缴社保" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.ss_pay" />
          </el-form-item>
          <el-form-item label="代缴个税" class="w-1/5">
            <el-input type="number" v-model="formData.tax_pay" />
          </el-form-item>
        </div>

        <div class="flex w-full">
          <el-form-item label="高温补贴" class="w-1/5">
            <el-input type="number" v-model="formData.heat_subsidy" />
          </el-form-item>
          <el-form-item label="社保补贴" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.social_insurance_subsidy" />
          </el-form-item>
          <el-form-item label="公积金补贴" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.accumulation_fund_subsidy" />
          </el-form-item>
          <el-form-item label="报销" class="w-1/5 px-1">
            <el-input type="number" v-model="formData.reimbursement" />
          </el-form-item>
          <el-form-item label="产假补贴" class="w-1/5">
            <el-input type="number" v-model="formData.other_subsidy" />
          </el-form-item>
        </div>

        <el-form-item label="备注(非必填，可多行)">
          <textarea v-model="formData.note" class="w-full p-2 font-sm leading-tight border text-gray-600 outline-none"
            rows="3"></textarea>
        </el-form-item>

        <div>
          <MAttachment v-model="formData.attachments" />
        </div>

        <el-form-item>
          <button @click.prevent="submit" class="text-gray-200 bg-indigo-700 px-6 rounded-lg shadow">
            {{ mode }}
          </button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import MAttachment from "@/components/MAttachment";
import ConfigSelect from "@/components/Select/ConfigSelect";
import ModelSelect from "@/components/Select/ModelSelect";

export default {
  mixins: [formMixin("wage")],
  components: { MForm, MAttachment, ConfigSelect, ModelSelect },
  data() {
    return {
      permisson: 100,
      formData: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        work_days: 0,
        basic: 0,
        full_day_pay: 0,
        merit_pay: 0,
        year_pay: 0,
        ss_pay: 0, // 代缴社保
        tax_pay: 0, // 代缴个税
        agent_bonus: 0,
        invoice_bonus: 0,
        task_bonus: 0,
        introduce_bonus: 0,
        heat_subsidy: 0,
        social_insurance_subsidy: 0,
        accumulation_fund_subsidy: 0,
        other_subsidy: 0, // 其他补贴
        reimbursement: 0,
        deduction: 0,
        note: "",
        attachments: [],
        user: null,
      },
    };
  },
  mounted() {
    if (this.$store.state.user.all.length < 1) {
      this.$store.dispatch(`user/all`);
    }
  },
  methods: {
    verify(data) {
      return data.year && data.month;
    },
  },
};
</script>
