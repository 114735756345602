<template>
  <MTableBoard
    title="代理公司信息"
    storeName="company"
    :permisson="1"
    :toolbarPermisson="1"
    :onlyMe="true"
  >
    <template v-slot:other-tool>
      <CompanyFilter />
    </template>

    <template v-slot:table>
      <company-table :border="false" />
    </template>
  </MTableBoard>
</template>

<script>
import CompanyTable from "./CompanyTable";
import MTableBoard from "@/components/MTableBoard";
import CompanyFilter from "./CompanyFilter";

export default {
  name: "CompanyBoard",
  components: { CompanyTable, MTableBoard, CompanyFilter }
};
</script>