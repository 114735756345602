<template>
  <div class="flex items-center justify-center bg-white text-indigo-400 cursor-pointer">
    <label>
      <span class="flex items-center justify-center cursor-pointer">
        <svg
          class="w-4 h-4 text-indigo-400 fill-current"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3297"
        >
          <path
            d="M964.44948 455.72a223.36 223.36 0 0 0-116.44-66.59A336.09 336.09 0 0 0 202.59948 269.2a333.4 333.4 0 0 0-26.25 119.93A224 224 0 0 0 224.17948 832h32a32 32 0 0 0 0-64h-32c-88.22 0-160-71.78-160-160a160 160 0 0 1 147-159.47 32 32 0 0 0 29.4-33.62c-0.27-5.06-0.41-10.08-0.41-14.9a272 272 0 0 1 544 0c0 4.83-0.14 9.84-0.41 14.9A32 32 0 0 0 813.17948 448.53 160 160 0 0 1 960.17948 608c0 88.22-71.78 160-160 160h-32a32 32 0 0 0 0 64h32a224 224 0 0 0 164.27-376.28z"
            p-id="3298"
          />
          <path
            d="M534.81948 553.39q-0.57-0.57-1.17-1.11l-0.5-0.43-0.69-0.59-0.71-0.56-0.51-0.39-0.82-0.58-0.45-0.31-0.87-0.56-0.45-0.28-0.88-0.5-0.49-0.27-0.85-0.43-0.56-0.28-0.79-0.36-0.66-0.29-0.72-0.28-0.76-0.29-0.64-0.22-0.86-0.28-0.58-0.16-0.94-0.25-0.56-0.13-1-0.21-0.59-0.1-0.95-0.16-0.68-0.09-0.86-0.1-0.93-0.07h-0.63q-1.59-0.08-3.19 0h-0.63l-0.92 0.07-0.86 0.1-0.68 0.09-1 0.16-0.58 0.1-1 0.21-0.55 0.13-0.94 0.25-0.57 0.16-0.87 0.28-0.64 0.21-0.77 0.29-0.71 0.28-0.67 0.29-0.78 0.35-0.57 0.28-0.84 0.43-0.49 0.27-0.87 0.5-0.45 0.28-0.87 0.56-0.45 0.31-0.82 0.59-0.5 0.39-0.72 0.57-0.66 0.57-0.52 0.45q-0.59 0.54-1.16 1.1l-160 160a32 32 0 0 0 45.25 45.25L480.17948 653.25V928a32 32 0 0 0 64 0V653.25l105.37 105.38a32 32 0 0 0 45.25-45.25z"
            p-id="3299"
          />
        </svg>
        上传附件
      </span>
      <input @change="uploadFile" type="file" class="hidden" />
    </label>
  </div>
</template>

<script>
import { upload } from "@/api";

export default {
  name: "MUpload",
  methods: {
    async uploadFile(e) {
      try {
        const res = await upload(e.target.files[0]);
        this.$emit("uploaded", res.data);
      } catch (e) {
        this.$notify.error({ message: `上传失败-${e}` });
      }
    }
  }
};
</script>