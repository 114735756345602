import { Wage as WageApi } from '../api'
import BaseStore from './base'


export default class Wage extends BaseStore {
  constructor() {
    super(WageApi, 'wage', true)
  }

  state() {
    return {
      ...super.state(),
      params: {
        size: 50, page: 1,
        filter: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
        },
        keywords: {}
      },
    }
  }

  actions() {
    const api = this.api
    return {
      ...super.actions(),
      async generate({dispatch}, { year, month }) {
        const res = await api.generate(year, month)
        if (res.data) dispatch('pagination', null)
        return res
      },
      async pay({dispatch}, uids) {
        // return api.pay(uids)
        const res = await api.pay(uids)
        if (res.data) dispatch('pagination', null)
        return res
      },
    }
  }

  mutations() {
    return {
      ...super.mutations(),
    }
  }
}