<template>
  <div>
    <div v-if="me.permisson > 10" class="flex justify-between items-center py-2 text-sm text-indigo-500 cursor-pointer">
      <ConfigSelect
        v-model="year"
        :clearable="false"
        config="years"
        class="pl-1 w-24"
        placeholder="年"
      />
      <a @click="exportCSV" class="px-2">导出Excel</a>
    </div>

    <el-table :data="tableData" :border="border">
      <el-table-column type="index" width="60"></el-table-column>

      <el-table-column prop="name" label="公司" width="240">
        <template slot-scope="scope">
          <el-link :underline="false" @click="edit(scope.row, 'company')">{{scope.row.name}}</el-link>
        </template>
      </el-table-column>

      <el-table-column prop="consumer_name" label="所有人" width="90"></el-table-column>

      <el-table-column label="周期收费" width="110">
        <template slot-scope="scope">{{`${scope.row.agent_fee}/${cycle(scope.row.cycle_months)}`}}</template>
      </el-table-column>

      <el-table-column prop="agent_fees" :label="year + ' 年代理费'">
        <template slot-scope="scope">
          <AgentFeeCell :company="scope.row" :year="year" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ConfigSelect from "@/components/Select/ConfigSelect";
import AgentFeeCell from "./AgentFeeCell";
import { tableMixin } from "@/mixins/table";
import mixin from "./mixin";

export default {
  mixins: [tableMixin("agentfee"), mixin],
  components: { ConfigSelect, AgentFeeCell },
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  computed: {
    tableData() {
      return this.items.map(i => ({
        ...i,
        user: i.user.uid,
        consumer: i.consumer.uid,
        consumer_name: i.consumer.name
      }));
    }
  },
  mounted() {
    this.$store.dispatch("user/all");
    this.$store.dispatch("consumer/all");
  },
  methods: {
    exportCSV() {
      this.$store.dispatch("agentfee/csv", {data: {year: this.year}, csv_name: `代理费${this.year}`});
    }
  }
};
</script>