<template>
  <div class="flex flex-col w-full text-blue-400">
    <div class="text-center text-lg pb-2 font-bold">
      费用明细
    </div>
    <div class="p-2 font-lg text-left">
      客户: <span class="underline">{{company}}</span>
    </div>
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="border w-1/4 py-1 font-lg">项目</th>
          <th class="border w-1/4 py-1 font-lg">单价</th>
          <th class="border w-1/4 py-1 font-lg">数量</th>
          <th class="border w-1/4 py-1 font-lg">总额</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, i) in fee" :key="i">
          <td
            v-for="k in ['item', 'price', 'account', 'total']"
            :key="`${i}${k}`"
            class="border py-1 text-center"
          >{{ record[k] }}</td>
        </tr>
      </tbody>
    </table>

    <textarea class="my-3" :value="note" />

    <div class="flex justify-between items-center">
      <div class="flex flex-col">
        <img class="w-20 h-20" src="https://www.yongxinkuaiji.com/images/wx_charg.png" />
        <span class="text-xs text-center">微信扫码支付</span>
      </div>
      <div class="p-1 font-lg"> 漳浦永信财务咨询有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: { type: String, required: true },
    cost: { type: Array, required: true },
    service: { type: Array, required: true },
    note: { type: String, default: null },
    advance: { type: Number, default: 0 },
  },
  computed: {
    fee() {
      let f = [
        ...this.cost,
        ...this.service.map(function(i) {
          return {
            item: i.service,
            account: 1,
            price: i.charge,
            total: i.charge
          };
        })
      ];
      const sum = f.map(i => i.total).reduce((a, b) => a + b, 0);
      return [
        ...f,
        { item: '预付款', price: "", account: "", total: this.advance},
        { item: "合计", price: "", account: "", total: sum - this.advance }];
    }
  }
};
</script>

<style>
</style>