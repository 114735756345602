<template>
  <span @click="deleteItem" class="w-full cursor-pointer">
    <svg class="w-6 h-6 p-1 text-red-400 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4028" > <path d="M864 192h-128V128c0-35.2-28.8-64-64-64H352c-35.2 0-64 28.8-64 64v64H160c-19.2 0-32 12.8-32 32s12.8 32 32 32h32v640c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64V256h32c19.2 0 32-12.8 32-32s-12.8-32-32-32zM352 128h320v64H352V128z m416 768H256V256h512v640z" p-id="4029" /> <path d="M352 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM512 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM640 384v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32s-32 12.8-32 32z" p-id="4030" /> </svg>
  </span>
</template>

<script>
export default {
  props: {
    uid: {type: String, required: true},
    delete: {type: Function, required: true},
    tip: {type: String, default: "此操作将永久删除该条记录，是否继续？"},
  },
  methods: {
    deleteItem() {
      this.$confirm(this.tip, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delete(this.uid)
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });          
        });
    }
  }
}
</script>

<style>

</style>