<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}客户信息`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">
        <el-form-item label="姓名(必填)">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="手机(必填,不可以跟其他可以重复)">
          <el-input v-model="formData.telphone"></el-input>
        </el-form-item>

        <el-form-item label="银行卡号">
          <el-input v-model="formData.bank_account"></el-input>
        </el-form-item>

        <el-form-item label="微信备注名">
          <el-input v-model="formData.wx_remark"></el-input>
        </el-form-item>

        <el-form-item label="微信账号">
          <el-input v-model="formData.wx_account"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="submit">{{mode}}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";

export default {
  mixins: [formMixin("consumer")],
  components: { MForm },
  data() {
    return {
      permisson: 100,
      formData: {
        name: "",
        telphone: "",
        bank_account: "",
        wx_remark: "",
        wx_account: ""
      }
    };
  },
  methods: {
    verify(data) {
      return data.name.length > 1 && data.telphone.length === 11;
    }
  }
};
</script>