<template>
    <MTableBoard
      title="代理收费"
      storeName="agentfee"
    >
      <template v-slot:other-tool>
        <AgentFeeFilter />
      </template>

      <template v-slot:table>
        <AgentFeeTable :border="false"/>
      </template>
    </MTableBoard>
</template>

<script>
import AgentFeeTable from "./AgentFeeTable";
import MTableBoard from "@/components/MTableBoard"
import AgentFeeFilter from "./AgentFeeFilter";

export default {
  name: "AgentFeeBoard",
  components: { AgentFeeTable, MTableBoard, AgentFeeFilter},
};
</script>