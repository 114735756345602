import { mapState } from "vuex";
import { firstUpperCase } from '@/helper'

export default function formMixin(storeName) {
  return {
    name: `${firstUpperCase(storeName)}Form`,
    props: {
      uid: {type: String, default: null}
    },
    computed: {
      ...mapState({
        me: state => state.user.me,
        batch: state => state[storeName].batch,
      }),
      isEdit() {
        return this.uid && this.uid !== 'new' && this.uid !== 'batch'
      },
      isBatch() {
        return this.uid === 'batch' && this.batch.length > 0
      },
      mode() {
        if (this.isBatch) {
          return '批量修改'
        }
        return this.isEdit  ?  '修改' :'添加'
      },
      hasEditPermisson() {
        return this.me.permisson >= 100 || this.formData.user === this.me.uid
      }
    },
    async mounted() {
      if (this.isEdit) {
        const res = await this.$store.dispatch(`${storeName}/get`, this.uid)
        this.mountResponse(res)
        /*
        if ('images' in this) {
          this.images = files2list(this.formData.images)
        }
        if ('attachments' in this) {
          this.attachments = files2list(this.formData.attachments)
        }
        */
      }
    },
    methods: {
      mountResponse(res) {
        // Object.keys(this.formData).map(k => this.formData[k] = res.data[k])
        const tmp = {}
        Object.keys(this.formData).map(function(k) {
          if (typeof res.data[k] === 'object' && res.data[k] && 'uid' in res.data[k]) {
            tmp[k] = res.data[k].uid
          } else {
            tmp[k] = res.data[k]
          }
        })
        this.formData = {...tmp}
        // this.formData = res.data
      },

      packData() {
        return this.formData
      },

      async create(data) {
        let load = this.$loading({ fullsreen: true });
        try {
          await this.$store.dispatch(`${storeName}/create`, data)        
          this.$notify.success('创建成功')
          this.$nextTick(() => load.close());
          this.$router.go(-1)
        } catch (e) {
          this.$notify.error('创建失败')
          load.close();
        }
      },

      async update(data, isBatch=false) {
        if (!this.hasEditPermisson) {
          this.$notify.error({message: "没有修改权限"})
          return 
        }

        // 批量修改与单个修改的的服务器route不一样, store中的action也不一样
        const action = isBatch ? 'batch_update' : 'update'
        try {
          await this.$store.dispatch(`${storeName}/${action}`, data)        
          this.$notify.success('修改成功')
          this.$router.go(-1)
        } catch (e) {
          this.$notify.error('修改失败')
        }
      },

      submit() {
        const data = this.packData()

        if (!this.verify(data)) {
          this.$notify.error({message: "表单数据格式不对!"})
        } else {
          // 批量修改
          if (this.isBatch) {
            const uid_list = this.batch.map(i => i.uid)
            const patch = {}
            Object.keys(data).forEach(i => {
              if (data[i] !== null) { patch[i] = data[i] }
            })
            this.update({uid_list, patch}, true)
          // 单个修改
          } else if (this.isEdit) {
            this.update({uid: this.uid, patch: {...data}})
          // 新建
          } else {
            this.create({data})
          }
        }

      },

    }
  }
}