import { target2url } from '@/helper'
import { imageSuffix } from '@/config'

export default {
  methods: {
    is_image(attachments) {
      return attachments.filter(i => imageSuffix.indexOf(i.target.split('.').pop().toLowerCase()) >= 0)
    },
    is_other(attachments) {
      return attachments.filter(i => imageSuffix.indexOf(i.target.split('.').pop().toLowerCase()) < 0)
    },
    attachments2urls(attachments) {
      return attachments.map(i => this.targetToUrl(i.target))
    },
    targetToUrl(target) {
      return target2url(target)
    },
  }
}