import Vue from 'vue'
import Vuex from 'vuex'
import BaseStore from './base'
import User from './user'
import Wage from './wage'
import fjgs from './fjgs'
// import ServiceFee from './service_fee'
import { Api } from '../api'


Vue.use(Vuex)

const models = ['company', 'consumer', 'task', 'service', 'agentfee', 'smstemplate', 'receipt', 'reimbursement']

function common(models) {
  let tmp = {}
  models.forEach(e => tmp[e] = new BaseStore(Api, e).toStore())
  return tmp
}


const store = new Vuex.Store({
  modules: {
    user: new User().toStore(),
    wage: new Wage().toStore(),
    fjgs,
    // servicefee: new ServiceFee().toStore(),
    ...common(models)
  }
})
export default store