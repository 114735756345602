<template>
  <el-form class="bg-white mt-4 p-4" label-position="top" label-width="80px" :model="password">
    <el-form-item label="旧密码">
      <el-input v-model="password.old" type="password"></el-input>
    </el-form-item>
    <el-form-item label="新密码">
      <el-input v-model="password.new1" type="password"></el-input>
    </el-form-item>
    <el-form-item label="再次确认">
      <el-input v-model="password.new2" type="password"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="onSubmit">修改</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: "ChangePassword",
  data() {
    return {
      password: { old: "", new1: "", new2:"" }
    }
  },
  computed: {
    ...mapState({me: state => state.user.me})
  },
  methods: {
    ...mapActions("user", {updateMe: "update"}),
    verifyFrom() {
      return this.password.old.length >= 6 &&
             this.password.new1.length >= 6 &&
             this.password.new2.length >= 6 &&
             this.password.new1 === this.password.new2
    },
    async onSubmit() {
      if (!this.verifyFrom()) {
        this.$notify.warning({ title: "注意", message: "请认真填写表单，密码长度不能小于6" });
        return
      }
      try {
        const patch = {
          password: {
            old: this.password.old,
            new: this.password.new1
          }
        }
        await this.updateMe({uid: this.me.uid, patch})
        this.$notify.success({ message: "密码修改成功" });
        this.$router.go(-1)
      } catch {
        this.$notify.error({ message: "密码修改失败" });
      }
    }
  }

}
</script>

<style>

</style>