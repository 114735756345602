<template>
  <div class="mt-4 p-6 bg-white" v-if="me.permisson >= permisson">
    <div class="w-full">
      <h3 class="text-gray-700 text-xl text-center font-semibold py-2">
        {{ title }}
      </h3>

      <m-toolbar
        :permisson="toolbarPermisson"
        :showSearch="showSearch"
        :keyword="params.keywords[searchField]"
      >
        <template v-slot:other-tool>
          <slot name="other-tool"></slot>
        </template>
      </m-toolbar>
    </div>

    <slot name="table"></slot>

    <div class="flex py-1 justify-center">
      <m-pagination
        :size="params.size"
        :current="params.page"
        :total="pagination.total"
        @sizeChange="changeSize"
        @currentChange="changeCurrent"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MToolbar from "./MToolbar";
import MPagination from "./MPagination";

export default {
  name: "MTableBoard",
  components: { MToolbar, MPagination },
  provide() {
    return {
      tableBoard: this,
    };
  },
  props: {
    title: { type: String, required: true },
    storeName: { type: String, required: true },
    permisson: { type: Number, default: 100 },
    showSearch: { type: Boolean, default: true },
    searchField: { type: String, default: "name" },
    toolbarPermisson: { type: Number, default: 100 },
    onlyMe: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      pagination: function(state) {
        return state[this.storeName].pagination;
      },
      params: function(state) {
        return state[this.storeName].params;
      },
      me: (state) => state.user.me,
    }),
  },
  mounted() {
    if (this.onlyMe && this.me.permisson <= 10) {
      this.$store.commit(`${this.storeName}/updateParams`, {
        ...this.params,
        filter: { ...this.params.filter, user: this.me.uid },
      });
    }
    this.getData();
  },
  methods: {
    async action(
      resolve,
      reject = () => {},
      successMsg = "操作成功",
      errorMsg = "操作失败"
    ) {
      let load = this.$loading({ fullsreen: true });
      try {
        await resolve();
        this.$nextTick(() => load.close());
        if (successMsg) this.$notify.success({ message: successMsg });
      } catch (e) {
        reject();
        load.close();
        if (errorMsg) this.$notify.error({ message: errorMsg });
      }
    },
    async getData(data = null) {
      this.action(
        () => this.$store.dispatch(`${this.storeName}/pagination`, data),
        () => {},
        null,
        "获取数据失败"
      );
    },
    add() {
      this.$router.push({
        name: `${this.storeName}-form`,
        params: { uid: "new" },
      });
    },
    get(params) {
      this.$store.commit(`${this.storeName}/updateParams`, params);
      this.getData();
    },
    getAll() {
      this.get({ size: 50, page: 1, filter: {}, keywords: {} });
    },
    filter() {
      this.get({ ...this.params, page: 1 });
    },
    selectRange(range, time_field = "date") {
      let data = null;
      if (range) {
        data = {
          time_field,
          start: range[0],
          end: range[1],
        };
      }
      this.get({ ...this.params, page: 1, range: data });
    },
    search(keyword) {
      const keywords = {};
      const field = /^\d{11}$/.test(keyword) ? "telphone" : this.searchField;
      if (keyword && keyword.length > 0) {
        keywords[field] = keyword;
      }
      this.get({
        ...this.params,
        page: 1,
        keywords,
      });
    },
    changeSize(size) {
      this.get({ ...this.params, size: size, page: 1 });
    },
    changeCurrent(current) {
      this.get({ ...this.params, page: current });
    },
  },
};
</script>
