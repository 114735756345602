<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="员工"
    />
    <ConfigSelect
      v-model="params.filter.year"
      @input="tableBoard.filter"
      :clearable="true"
      config="years"
      class="pl-1 w-24"
      placeholder="年"
    />
    <ConfigSelect
      v-model="params.filter.month"
      @input="tableBoard.filter"
      :clearable="true"
      config="months"
      class="pl-1 w-24"
      placeholder="月"
    />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "WageFilter",
  mixins: [filterMixin("wage")],
  components: { ModelSelect, ConfigSelect }
};
</script>