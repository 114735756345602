<template>
  <MTableBoard :showSearch="false" title="工资" storeName="wage">
    <template v-slot:other-tool>
      <WageFilter />
    </template>

    <template v-slot:table>
      <WageTable :border="true" />
    </template>
  </MTableBoard>
</template>

<script>
import WageTable from "./WageTable";
import WageFilter from "./WageFilter";
import MTableBoard from "@/components/MTableBoard";

export default {
  name: "WageBoard",
  components: { WageTable, WageFilter, MTableBoard }
};
</script>