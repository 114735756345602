<template>
  <div>
    <div class="flex justify-between py-2">
      <div>
        <el-checkbox v-for="(col, idx) in toggleColumns" v-model="col.show" :key="idx">{{ col.label }}</el-checkbox>
      </div>
      <div>
        <span v-for="tool in tools" v-show="me.permisson >= tool.permisson" :key="tool.label" @click="tool.do"
          class="px-1 text-sm text-indigo-500 cursor-pointer">{{ tool.label }}</span>
      </div>
    </div>

    <el-table :data="tableData" :border="border" :row-class-name="rowClass" @selection-change="select">
      <el-table-column type="selection" :width="25"></el-table-column>

      <el-table-column prop="name" label="业务" v-if="toggleColumns.service.show">
        <template slot-scope="scope">
          <span class="flex items-center">
            <span class="pr-1 text-indigo-400">{{ scope.$index + 1 }}</span>
            <el-image v-if="scope.row.attachments &&
              is_image(scope.row.attachments).length > 0
              " style="width: 20px; height: 20px; margin-right: 5px"
              :src="attachments2urls(is_image(scope.row.attachments))[0]" :preview-src-list="attachments2urls(is_image(scope.row.attachments))
                "></el-image>
            <span @click="edit(scope.row)" class="cursor-pointer">{{
              scope.row.service.name
            }}</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="note" label="备注" v-if="toggleColumns.note.show"></el-table-column>

      <el-table-column prop="company" label="公司" v-if="toggleColumns.company.show">
        <template slot-scope="scope">
          <span @click="filterCurrent('company', scope.row.company.uid)" class="cursor-pointer">{{ scope.row.company.name
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="user" label="负责人" width="55">
        <template slot-scope="scope">
          <span @click="filterCurrent('user', scope.row.user.uid)" class="cursor-pointer">{{ scope.row.user.name }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="advance" label="预付款" v-if="me.permisson >= 100 && toggleColumns.advance.show"
        width="50"></el-table-column>

      <el-table-column prop="bonus" label="奖金" width="40" v-if="toggleColumns.bonus.show">
        <template slot-scope="scope">{{
          scope.row.service.bonus + scope.row.bonus_offset
        }}</template>
      </el-table-column>

      <el-table-column prop="paid" label="奖金支付" width="80">
        <template slot-scope="scope">
          <el-switch v-if="!scope.row.paid" :width="30" :disabled="me.permisson < 100"
            :active-text="scope.row.paid ? '已付' : '未付'" v-model="scope.row.paid"
            @change="changePaidStatus(scope.row, 'paid')"></el-switch>
          <span v-else>{{
            scope.row.paid_note ? scope.row.paid_note : "已付"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="me.permisson >= 100 && toggleColumns.charge.show" prop="charge" label="垫付+业务费+调整"
        width="150">
        <template slot-scope="scope">{{
          `${scope.row.cost}+${scope.row.service.charge}+${scope.row.offset
            }=${scope.row.cost + scope.row.offset + scope.row.service.charge}`
        }}</template>
      </el-table-column>

      <el-table-column prop="charge" label="收款情况" width="80" v-if="me.permisson >= 100">
        <template slot-scope="scope">
          <el-switch v-if="!scope.row.charged" :width="30" :disabled="me.permisson < 100"
            :active-text="scope.row.charged ? '已收' : '未收'" v-model="scope.row.charged"
            @change="changePaidStatus(scope.row, 'charged')"></el-switch>
          <span v-else>{{
            scope.row.charged_note ? scope.row.charged_note : "已收"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <ConfigSelect v-if="me.permisson >= 100 || scope.row.user.uid === me.uid" v-model="scope.row.status"
            @input="changeStatus(scope.row)" config="taskStatuses" />
          <span v-else>{{ status(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="complete_time" label="完成时间" width="85" v-if="toggleColumns.complete_time.show">
        <template slot-scope="scope">
          <span v-if="scope.row.complete_time">{{
            scope.row.complete_time | date
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="attachments" label="附件" v-if="toggleColumns.attachments.show">
        <template slot-scope="scope">
          <el-link v-for="atm in is_other(scope.row.attachments)" :key="atm.target" :href="targetToUrl(atm.target)"
            :download="atm.name">{{ atm.source }}</el-link>
        </template>
      </el-table-column>

      <el-table-column width="40" v-if="me.permisson >= 100">
        <template slot-scope="scope">
          <DeleteButton :delete="deleteItem" :uid="scope.row.uid" />
        </template>
      </el-table-column>
    </el-table>

    <div class="flex my-2 items-center">
      <div v-if="me.permisson >= 100" class="flex items-center text-indigo-500 text-sm">
        <span>应收费</span>
        <span class="text-indigo-400 px-2 border-b">{{ allCharge }}</span>
        <span>已收</span>
        <span class="text-teal-400 px-2 border-b">{{ allCharged }}</span>
        <span>未收</span>
        <span class="text-pink-400 px-2 border-b">{{
          allCharge - allCharged
        }}</span>
      </div>

      <div class="flex items-center mx-4 text-indigo-500 text-sm">
        <span>奖金</span>
        <span class="text-indigo-400 px-2 border-b">{{ allBonus }}</span>
        <span>已发</span>
        <span class="text-teal-400 px-2 border-b">{{ allBonusPaid }}</span>
        <span>未发</span>
        <span class="text-pink-400 px-2 border-b">{{
          allBonus - allBonusPaid
        }}</span>
      </div>

      <div v-if="me.permisson >= 100" class="flex items-center text-indigo-500 text-sm">
        <span>垫付</span>
        <span class="text-indigo-400 px-2 border-b">{{ allCost }}</span>
        <span>奖金+垫付</span>
        <span class="text-indigo-400 px-2 border-b">{{
          allBonus + allCost
        }}</span>
      </div>
    </div>

    <el-dialog :visible.sync="showFeeSheet">
      <TaskFeeSheet :company="company" :cost="cost" :service="service" :note="note" :advance="advance" />
    </el-dialog>
  </div>
</template>

<script>
import { filters, arrayToCSV } from "@/helper";
import { select } from "@/config";
import attachmentMixin from "@/mixins/attachment";
import confirmMixin from "@/mixins/confirm";
import { tableMixin } from "@/mixins/table";
import ConfigSelect from "@/components/Select/ConfigSelect";
import TaskFeeSheet from "./TaskFeeSheet";
import DeleteButton from "@/components/DeleteButton";

export default {
  mixins: [tableMixin("task"), attachmentMixin, confirmMixin],
  components: { ConfigSelect, TaskFeeSheet, DeleteButton },
  data() {
    return {
      showFeeSheet: false,
      // 改变奖金支付和收款状态是，跳出弹窗提示输入日期和备注
      showNoteWithDate: false,
      clipBoard: {},
      toggleColumns: {
        service: { show: true, label: "业务" },
        note: { show: true, label: "备注" },
        company: { show: true, label: "公司" },
        attachments: { show: true, label: "附件" },
        bonus: { show: true, label: "奖金" },
        advance: { show: true, label: "预付款" },
        charge: { show: true, label: "收费" },
        complete_time: { show: false, label: "完成日期" },
      },
      tools: [
        { permisson: 100, do: () => this.batchTrue("charged"), label: "收费" },
        { permisson: 100, do: this.copyNote, label: "复制备注" },
        { permisson: 100, do: this.pasteNote, label: "粘贴备注" },
        { permisson: 100, do: this.copyAttachment, label: "复制附件" },
        { permisson: 100, do: this.pasteAttachment, label: "粘贴附件" },
        { permisson: 100, do: this.exportBonusCSV, label: "导出提成" },
        { permisson: 100, do: this.generateFeeSheet, label: "费用清单" },
      ],
    };
  },
  computed: {
    /*
    tableData() {
      let items = [...this.items];
      if (this.me.permisson < 100) {
        items.sort((a, b) => a.status - b.status);
      } else {
        items.sort((a, b) => a.charged - b.charged);
      }
      return items;
    },
    */
    company() {
      return this.selected.length > 0 ? this.selected[0].company.name : "";
    },
    cost() {
      return this.selected
        .filter((i) => i.cost > 0)
        .map((i) => i.task_fee)
        .flat();
    },
    // 有收费的
    service() {
      return this.selected.map(function (i) {
        return {
          service: i.service.name,
          charge: i.service.charge + i.offset,
        };
      }); //.filter(i => i.charge > 0)
    },
    note() {
      return this.selected
        .map((i) => i.note)
        .filter((i) => i.length > 0)
        .join("\n");
    },
    // 预付款
    advance() {
      return this.selected.map((i) => +i.advance).reduce((a, b) => a + b, 0);
    },
    // 所有收费
    allCharge() {
      return this.tableData
        .map((i) => i.cost + i.service.charge + i.offset)
        .reduce((a, b) => a + b, 0);
    },
    // 所有已收费
    allCharged() {
      return this.tableData
        .filter((i) => i.charged)
        .map((i) => i.cost + i.service.charge + i.offset)
        .reduce((a, b) => a + b, 0);
    },
    allBonus() {
      return this.tableData
        .map((i) => i.service.bonus + i.bonus_offset)
        .reduce((a, b) => a + b, 0);
    },
    allBonusPaid() {
      return this.tableData
        .filter((i) => i.paid)
        .map((i) => i.service.bonus)
        .reduce((a, b) => a + b, 0);
    },
    allCost() {
      return this.tableData.map((i) => i.cost).reduce((a, b) => a + b, 0);
    },
  },
  filters: {
    ...filters,
  },
  mounted() {
    this.$store.dispatch(`user/all`);
    this.$store.dispatch(`consumer/all`);
    this.$store.dispatch(`company/all`);
    this.$store.dispatch(`service/all`);
  },
  methods: {
    isSelectedSameCompany() {
      const uid = this.selected[0].company.uid;
      return !this.selected.map((i) => i.company.uid).every((i) => i === uid);
    },
    copyNote() {
      this.verifySelected();
      this.clipBoard.note = this.selected.map((i) => i.note).join("\n");
      this.$notify.success({
        message: `成功复制了${this.selected.length}条备注`,
      });
    },
    pasteNote() {
      if (!this.clipBoard.note) {
        this.$notify.error({ message: "请先复制备注" });
      }
      this.verifySelected();
      this.selected.forEach((i) => {
        this.update({
          uid: i.uid,
          patch: { note: `${i.note}\n${this.clipBoard.note}` },
        });
      });
    },
    copyAttachment() {
      this.verifySelected();
      const attachment = this.selected
        .map((i) => i.attachments)
        .reduce((a, b) => [...a, ...b], []);
      this.clipBoard.attachments = attachment;
      this.$notify.success({ message: `成功复制了${attachment.length}个附件` });
    },
    pasteAttachment() {
      if (
        !this.clipBoard.attachments ||
        this.clipBoard.attachments.length < 1
      ) {
        this.$notify.error({ message: "请先复制附件" });
      }
      this.verifySelected();
      this.selected.forEach((i) => {
        this.update({
          uid: i.uid,
          patch: {
            attachments: [...i.attachments, ...this.clipBoard.attachments],
          },
        });
      });
    },
    exportBonusCSV() {
      this.verifySelected();
      let data = this.selected.map(function (i) {
        return [
          i.service.name,
          i.service.bonus + i.bonus_offset,
          i.company.name,
          i.user.name,
          filters.date(i.complete_time),
        ];
      });
      data = [
        ["业务名称", "提成(元)", "公司", "办事人", "完成时间"],
        ...data,
        ["合计", this.allBonus, "", "", ""],
      ];
      arrayToCSV(data, "提成清单");
    },
    generateFeeSheet() {
      this.verifySelected();
      if (this.isSelectedSameCompany()) {
        this.$notify.error({ message: "只能选择同一家公司" });
        return false;
      }
      this.showFeeSheet = !this.showFeeSheet;
    },
    status(status) {
      return select.taskStatuses[status];
    },
    changeStatus(row) {
      const uid = row.uid;
      let patch = {
        status: row.status,
      };
      const sms_template = row.service.sms_template;
      // 2 为已完成
      patch.complete_time = row.status == 2 ? new Date().getTime() : null;
      if (row.status === 2 && sms_template && sms_template.temp_id) {
        this.confirm("是否给客户发送短信通知", "发送", "不发送")
          .then(() => {
            patch.sms = {
              temp_id: sms_template.temp_id,
              to: row.company.consumer.telphone,
              params: [row.company.name, row.user.name, row.user.telphone],
            };
            this.update({ uid, patch });
          })
          .catch(() => this.update({ uid, patch }));
      } else {
        this.update({ uid, patch });
      }
    },
    rowClass({ row }) {
      switch (row.status) {
        case 0:
          return "unrecieve";
        case 1:
          return "doing";
        case 2:
          return "done";
        case 9:
          return "pause";
      }
    },
  },
};
</script>

<style>
.el-table .unrecieve {
  background: #fed7d7;
}

.el-table .doing {
  background: #bee3f8;
}

.el-table .pause {
  background: #c4b5fd;
}

/*
.el-table .done {
  background: #c6f6d5;
}
*/
</style>
