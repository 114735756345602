<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}代理公司信息`">
    <template v-slot:form>
      <div v-if="isBatch">
        <h3 class="text-red-500 py-2">
          注意：以下操作会对 {{ batch[0].name }} 等
          {{ batch.length }}
          家公司的信息做出修改，请谨慎操作。空白的表单项不会被修改。
        </h3>
      </div>
      <el-form label-position="top" label-width="80px" :model="formData">
        <div class="flex w-full">
          <el-form-item v-if="!isBatch" label="公司名称" class="w-1/5">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>

          <el-form-item label="每个周期代理费" class="w-1/5 px-1">
            <el-input v-model="formData.agent_fee"></el-input>
          </el-form-item>

          <el-form-item label="收费周期" class="w-1/5 px-1">
            <ConfigSelect
              v-model="formData.cycle_months"
              config="cycleMonths"
            />
          </el-form-item>

          <el-form-item label="开始代理日期" class=" px-1">
            <el-date-picker
              v-model="formData.agent_date"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="离开日期" class="w-1/5">
            <el-date-picker
              v-model="formData.leave_time"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="flex w-full">
          <el-form-item label="代理人" class="w-1/5">
            <ModelSelect v-model="formData.user" model="user" />
          </el-form-item>

          <el-form-item label="代理提成百分比" class="w-1/5 px-1">
            <el-input v-model="formData.commission" type="number"></el-input>
          </el-form-item>

          <el-form-item label="开票人" class="w-1/5 px-1">
            <ModelSelect v-model="formData.user_invoice" model="user" />
          </el-form-item>

          <el-form-item label="开票提成百分比" class="w-1/5 px-1">
            <el-input v-model="formData.invoice_commission" type="number"></el-input>
          </el-form-item>
        </div>

        <div class="flex w-full">
          <el-form-item label="所有人" class="w-1/5">
            <ModelSelect v-model="formData.consumer" model="consumer" />
          </el-form-item>

          <el-form-item label="类型" class="w-1/5 px-1">
            <ConfigSelect
              v-model="formData.category"
              config="companyCategories"
            />
          </el-form-item>
          <el-form-item label="获客方式" class="w-1/5 px-1">
            <ConfigSelect v-model="formData.acquisition" config="acquisition" />
          </el-form-item>
          <el-form-item label="引荐人(员工)" class="w-1/5 px-1">
            <ModelSelect v-model="formData.referrer" model="user" />
          </el-form-item>

          <el-form-item label="引荐人(客户)" class="w-1/5">
            <ModelSelect
              v-model="formData.referrer_consumer"
              model="consumer"
            />
          </el-form-item>
        </div>

        <el-form-item>
          <el-button @click="submit">{{ mode }}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";

export default {
  mixins: [formMixin("company")],
  components: { MForm, ModelSelect, ConfigSelect },
  data() {
    return {
      permisson: 100,
      formData: {
        name: "",
        agent_fee: 200,
        commission: 20,
        invoice_commission: 5,
        cycle_months: 1,
        agent_date: null,
        leave_time: null,
        category: 1,
        user: null,
        user_invoice: null,
        consumer: null,
        acquisition: 4,
        referrer: null,
        referrer_consumer: null,
      },
    };
  },
  mounted() {
    if (this.isBatch) {
      this.formData = {
        name: null,
        agent_fee: null,
        commission: null,
        invoice_commission: null,
        cycle_months: null,
        agent_date: null,
        category: null,
        user: null,
        user_invoice: null,
        consumer: null,
      };
    }
  },
  methods: {
    verify(data) {
      if (this.isBatch) {
        return (
          data.name === null && Object.values(data).some((i) => i !== null)
        );
      } else {
        return (
          [data.name, data.consumer, data.user].every((i) => i.length >= 5) &&
          data.agent_fee >= 0 &&
          data.cycle_months > 0
        );
      }
    },
  },
};
</script>
