<template>
  <table class="table-fixed">
    <thead>
      <tr>
        <th class="border w-1/4 py-2 font-normal text-gray-800">项目</th>
        <th class="border w-1/4 py-2 font-normal text-gray-800">单价</th>
        <th class="border w-1/4 py-2 font-normal text-gray-800">数量</th>
        <th class="border w-1/4 py-2 font-normal text-gray-800">总额</th>
        <th class="border py-2 font-normal text-gray-800">
          <div class="w-12">删除</div>
        </th>
      </tr>
    </thead>
    <tbody v-if="value">
      <tr v-for="(record, i) in value" :key="i">
        <td v-for="k in ['item', 'price', 'account', 'total']" :key="`${i}${k}`" class="border ">
          <input
            :type="k === 'item' ? 'text': 'number'"
            :disabled="k === 'total'"
            :ref="`${i}_${k}`"
            :value="record[k]" @blur="change(i, k)"
            class="w-full py-2 px-2 bg-gray-100 focus:bg-white">
        </td>

        <td class="border text-center">
          <span @click="del(i)" class="text-xs text-pink-500 cursor-pointer">删除</span>
        </td>
      </tr>
    </tbody>
  </table>
  
</template>

<script>
export default {
  props: {value: {required: true}},
  methods: {
    change(idx, key) {
      const val = this.$refs[`${idx}_${key}`][0].value
      let row = this.value[idx]
      row[key] = val
      row.total = row.price * row.account

      let new_value = [...this.value]
      new_value[idx] = row
      this.$emit('input', new_value)
    },
    del(idx) {
      let new_value = [...this.value]
      new_value.splice(idx, 1)
      this.$emit('input', new_value)
    }
  }
}
</script>