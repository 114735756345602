<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="负责人"
    />
    <ConfigSelect
      v-model="params.filter.status"
      @input="tableBoard.filter"
      :clearable="true"
      config="taskStatuses"
      class="pl-1 w-24"
      placeholder="状态"
    />
    <ConfigSelect
      v-model="params.filter.charged"
      @input="tableBoard.filter"
      :clearable="true"
      config="feeStatuses"
      class="pl-1 w-24"
      placeholder="收款"
    />
    <ModelSelect
      v-model="params.filter.company"
      @input="tableBoard.filter"
      :clearable="true"
      model="company"
      class="pl-1"
      placeholder="公司"
    />
    <ModelSelect
      v-model="params.filter.service"
      @input="tableBoard.filter"
      :clearable="true"
      model="service"
      class="px-1 w-24"
      placeholder="业务"
    />
    <MDatePicker @picked="(range) => tableBoard.selectRange(range, 'complete_time')" />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import MDatePicker from "@/components/MDatePicker";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "TaskFilter",
  mixins: [filterMixin("task")],
  components: { ModelSelect, ConfigSelect, MDatePicker },
};
</script>