<template>
  <div class="flex-col">

    <div class="flex items-center justify-between w-full">
      <span>附件</span>
      <MUpload @uploaded="uploaded" class="text-sm border rounded-sm ml-2 p-1"/>
    </div>

    <div class="flex items-center justify-start">
      <div
        v-for="(img, i) in images"
        :key="i"
        class="relative w-20 h-20 m-1 overflow-hidden rounded-lg object-cover object-center"
      >
        <el-image :src="targetToUrl(img.target)" :preview-src-list="[targetToUrl(img.target)]" />
        <span
          @click="deleteImage(i)"
          class="absolute left-0 bottom-0 flex items-center h-6 w-full justify-center bg-white opacity-75 font-semibold text-red-400 cursor-pointer"
        >
          <svg class="w-6 h-6 p-1 text-red-400 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4028" > <path d="M864 192h-128V128c0-35.2-28.8-64-64-64H352c-35.2 0-64 28.8-64 64v64H160c-19.2 0-32 12.8-32 32s12.8 32 32 32h32v640c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64V256h32c19.2 0 32-12.8 32-32s-12.8-32-32-32zM352 128h320v64H352V128z m416 768H256V256h512v640z" p-id="4029" /> <path d="M352 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM512 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM640 384v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32s-32 12.8-32 32z" p-id="4030" /> </svg>
        </span>
      </div>
    </div>

    <div class="flex-col items-start justify-start">
      <div
        v-for="(atm, i) in others"
        :key="i"
        class="w-full flex items-center justify-start py-1"
      >
        <span @click="deleteOther(i)" class="cursor-pointer">
          <svg class="w-6 h-6 text-red-400 p-1 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4028" > <path d="M864 192h-128V128c0-35.2-28.8-64-64-64H352c-35.2 0-64 28.8-64 64v64H160c-19.2 0-32 12.8-32 32s12.8 32 32 32h32v640c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64V256h32c19.2 0 32-12.8 32-32s-12.8-32-32-32zM352 128h320v64H352V128z m416 768H256V256h512v640z" p-id="4029" /> <path d="M352 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM512 352c-19.2 0-32 12.8-32 32v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32zM640 384v384c0 19.2 12.8 32 32 32s32-12.8 32-32V384c0-19.2-12.8-32-32-32s-32 12.8-32 32z" p-id="4030" /> </svg>
        </span>
        <a class="text-gray-600 text-sm" :href="targetToUrl(atm.target)">{{atm.source}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import MUpload from './MUpload'
import attachmentMixin from '@/mixins/attachment'

export default {
  components: {MUpload},
  mixins: [attachmentMixin],
  props: {
    value: {type: Array, required: true}
  },
  computed: {
    images() {
      return this.is_image(this.value)
    },
    others() {
      return this.is_other(this.value)
    }
  },
  methods: {
    deleteImage(index) {
      let images = this.images
      images.splice(index, 1)
      const att = [...images, ...this.others]
      this.$emit('input', att)
    },
    deleteOther(index) {
      let others = this.others
      others.splice(index, 1)
      const att = [...this.images, ...others]
      this.$emit('input', att)
    },
    uploaded(item) {
      this.$emit('input', [...this.value, item])
    },
  }
};
</script>