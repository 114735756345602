<template>
  <div>
    <div v-if="me.permisson >= permisson" class="flex justify-between py-2">
      <div class="flex justify-start">
        <el-button @click="tableBoard.add" icon="el-icon-circle-plus" circle></el-button>
        <el-button @click="tableBoard.getAll">所有</el-button>
        <el-button @click="tableBoard.getData">刷新</el-button>
        <slot name="other-tool"></slot>
      </div>

      <div v-if="showSearch" class="pl-2 relative">
        <!-- <el-input v-model="keyword" @keyup.enter.native="search" placeholder="搜索">
        <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
        </el-input>-->
        <svg
          v-if="keyword && keyword.length > 0"
          @click="tableBoard.search(null)"
          class="fill-current absolute w-4 h-4 right-0 text-gray-500 mt-3 mr-2 cursor-pointer"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M512 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m0-981.333333a469.333333 469.333333 0 1 0 469.333333 469.333333A469.333333 469.333333 0 0 0 512 42.666667z m207.402667 676.437333a20.224 20.224 0 0 1-28.629334 0l-178.944-178.944-178.944 178.944a20.245333 20.245333 0 1 1-28.629333-28.629333l178.944-178.944-178.645333-178.645334a20.245333 20.245333 0 0 1 28.629333-28.629333l178.645333 178.645333 178.645334-178.645333a20.245333 20.245333 0 1 1 28.629333 28.629333l-178.645333 178.645334 178.944 178.944a20.224 20.224 0 0 1 0 28.629333z"
            p-id="3439"
          />
        </svg>
        <input
          :value="keyword"
          ref="keyword"
          @keyup.enter="search"
          class="p-2 bg-gray-100 shadow-inner font-light text-sm text-gray-600 rounded-lg outline-none"
          placeholder="按回车搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MToolbar",
  inject: ["tableBoard"],
  props: {
    keyword: { required: true },
    showSearch: { type: Boolean, default: true },
    permisson: { type: Number, default: 100 }
  },
  computed: {
    ...mapState({ me: state => state.user.me })
  },
  methods: {
    search() {
      const keyword = this.$refs.keyword.value.trim();
      if (keyword.length > 0) {
        this.tableBoard.search(keyword);
      }
    }
  }
};
</script>

<style scoped>
.toolbar {
  padding-bottom: 10px;
}
</style>