<template>
  <MTableBoard title="交接单" storeName="receipt" :permisson="1" :toolbarPermisson="1" :onlyMe="true">
    <template v-slot:other-tool>
      <ReceiptFilter />
    </template>

    <template v-slot:table>
      <ReceiptTable :border="false" />
    </template>
  </MTableBoard>
</template>

<script>
import ReceiptTable from "./ReceiptTable";
import ReceiptFilter from "./ReceiptFilter";
import MTableBoard from "@/components/MTableBoard";

export default {
  name: "ReceiptBoard",
  components: { ReceiptTable, ReceiptFilter, MTableBoard }
};
</script>