<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}员工`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">
        <el-form-item label="姓名">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="手机">
          <el-input v-model="formData.telphone"></el-input>
        </el-form-item>

        <el-form-item label="身份证">
          <el-input v-model="formData.id_number"></el-input>
        </el-form-item>

        <el-form-item label="基本工资">
          <el-input type="number" v-model="formData.basic_wage"></el-input>
        </el-form-item>

        <el-form-item label="满勤奖">
          <el-input type="number" v-model="formData.full_day_pay"></el-input>
        </el-form-item>

        <el-form-item label="补贴">
          <el-input type="number" v-model="formData.merit_pay"></el-input>
        </el-form-item>

        <el-form-item label="工龄奖">
          <el-input type="number" v-model="formData.year_pay"></el-input>
        </el-form-item>

        <el-form-item label="代缴社保">
          <el-input type="number" v-model="formData.ss_pay"></el-input>
        </el-form-item>

        <el-form-item label="入职日期">
          <el-date-picker v-model="formData.join_time" type="date" value-format="timestamp"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="离职日期">
          <el-date-picker v-model="formData.leave_time" type="date" value-format="timestamp"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button @click="submit">{{ mode }}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";

export default {
  mixins: [formMixin("user")],
  components: { MForm },
  data() {
    return {
      permisson: 100,
      formData: {
        name: "",
        telphone: "",
        id_number: "",
        basic_wage: 0,
        full_day_pay: 0,
        merit_pay: 0,
        year_pay: 0,
        join_time: new Date().getTime(),
        leave_time: null,
      },
    };
  },
  methods: {
    packData() {
      let data = { ...this.formData };
      const uid = this.formData.uid;
      delete data["uid"];
      return { uid, ...data };
    },

    verify(data) {
      return (
        data.name.length > 1 &&
        data.telphone.length === 11 &&
        data.id_number.length === 18 &&
        data.basic_wage >= 0 &&
        data.full_day_pay >= 0 &&
        data.year_pay >= 0 &&
        data.merit_pay >= 0
      );
    },
  },
};
</script>