<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="current"
    :page-sizes="sizes"
    :page-size="size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
  ></el-pagination>
</template>

<script>
export default {
  name: "MPagination",
  props: {
    total: { type: Number, required: true },
    size: { type: Number, default: 50 },
    sizes: { type: Array, default: () => [10, 50, 100, 500, 1000] },
    current: { type: Number, default: 1 }
  },
  methods: {
    handleSizeChange(size) {
      this.$emit("sizeChange", size);
    },
    handleCurrentChange(current) {
      this.$emit("currentChange", current);
    }
  }
};
</script>

<style>
</style>
