<template>
  <div class="flex w-full" @drop="handleDrop" @dragover="handleDragover">
    <img v-show="src" :src="src" />
    <div class="w-1/2">
      <vue-croppie
        ref="croppie"
        :enableOrientation="true"
        :boundary="{ width: 400, height: 300}"
        @result="result"
        @update="update"
      ></vue-croppie>
    </div>
    <div calss="w-1/2">ddd</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crupped: null,
      src: null
    };
  },
  methods: {
    handleDrop(e) {
      e.preventDefault();
      const fi = this.getFileInput();
      // fi.files = e.dataTransfer.files;
      console.dir(fi);
      console.log(e.dataTransfer.files);
      this.src = URL.createObjectURL(e.dataTransfer.files[0]);
      const f = e.dataTransfer.files[0];
      const fr = new FileReader();
      fr.readAsDataURL(f);
      console.log(fr);
      this.$refs.croppie.bind({
        src: URL.createObjectURL(e.dataTransfer.files[0])
      });
    },
    handleDragover(e) {
      // console.log(e);
      e.preventDefault();
    },
    result(val) {
      this.crupped = val;
    },
    update(e) {
      console.log(e);
    }
  }
};
</script>

<style></style>