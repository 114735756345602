import {local as api} from '@/api'

export default {
  namespaced: true,
  state: {
    companys: [],
    info: {}
  },
  actions: {
    async getCompanys({commit}) {
      const res = await api('json_files')
      commit('setCompanys', res.data)
    },
    async getInfo({commit}, params) {
      if (params.company) {
        const res = await api('read_info', params)
        commit('setInfo', res.data)
      } else {
        commit('setInfo', {})
      }
    },
  },
  mutations: {
    setCompanys(state, data) {
      state.companys = data
    },
    setInfo(state, data) {
      state.info = data
    },
  }
}