<template>
  <div class="my-6">
    <div class="flex">
      <el-select
        v-model="company"
        @change="select"
        placeholder="选择公司"
        clearable
        filterable
        class="px-1 w-3/4"
      >
        <el-option v-for="(com, i) in companys" :key="i" :label="com" :value="com" />
      </el-select>
      <div v-if="company" class="w-1/4 flex justify-between">
        <button
          v-for="btn in buttons1"
          :key="btn.label"
          @click="btn.do"
          class="w-1/3 bg-white mx-1 rounded outline-none shadow"
        >{{ btn.label }}</button>
      </div>
    </div>

    <div class="flex py-2">
      <button
        v-for="btn in buttons2"
        :key="btn.label"
        @click="btn.do"
        class="w-1/4 bg-white mx-1 py-1 rounded outline-none shadow"
      >{{ btn.label }}</button>
    </div>

    <OCR v-if="show_ocr" v-model="ocr_result" class="bg-white p-2" />

    <div v-if="info.name">
      <el-form label-position="top" label-width="80px" :model="info">
        <div class="flex">
          <el-form-item label="公司名称" class="px-1 w-1/2">
            <el-input v-model="info.name"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" class="px-1 w-1/2">
            <el-input v-model="info.addr"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="监管机构" class="px-1 w-1/4">
            <el-input v-model="info.gov"></el-input>
          </el-form-item>
          <el-form-item label="企业类型" class="px-1 w-1/4">
            <el-input v-model="info.thread"></el-input>
          </el-form-item>
          <el-form-item label="冠名类型" class="px-1 w-1/4">
            <el-input v-model="info.sponsor_type"></el-input>
          </el-form-item>
          <el-form-item label="组织形式" class="px-1 w-1/4">
            <el-input v-model="info.form"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="冠名区划" class="px-1 w-1/4">
            <el-input v-model="info.country_option"></el-input>
          </el-form-item>
          <el-form-item label="字号" class="px-1 w-1/4">
            <el-input v-model="info.zihao"></el-input>
          </el-form-item>
          <el-form-item label="字号拼音" class="px-1 w-1/4">
            <el-input v-model="info.zihao_pinyin"></el-input>
          </el-form-item>
          <el-form-item label="行业" class="px-1 w-1/4">
            <el-input v-model="info.name_industry"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="面积" class="px-1 w-1/4">
            <el-input v-model="info.area"></el-input>
          </el-form-item>
          <el-form-item label="注册资本" class="px-1 w-1/4">
            <el-input v-model="info.capital"></el-input>
          </el-form-item>
          <el-form-item label="电话" class="px-1 w-1/4">
            <el-input v-model="info.tel"></el-input>
          </el-form-item>
          <el-form-item label="邮编" class="px-1 w-1/4">
            <el-input v-model="info.post_code"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="经营范围(名称核准)">
          <el-input type="textarea" v-model="approval_scope" disabled></el-input>
        </el-form-item>
        <el-form-item label="经营范围(开业登记)">
          <el-input type="textarea" v-model="establishment_scope" disabled></el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item label="法人姓名" class="px-1 w-1/4">
            <el-input v-model="info.legal.name"></el-input>
          </el-form-item>
          <el-form-item label="法人电话" class="px-1 w-1/4">
            <el-input v-model="info.legal.tel"></el-input>
          </el-form-item>
          <el-form-item label="法人身份证" class="px-1 w-1/4">
            <el-input v-model="info.legal.id"></el-input>
          </el-form-item>
          <el-form-item label="法人地址" class="px-1 w-1/4">
            <el-input v-model="info.legal.addr"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="监事姓名" class="px-1 w-1/3">
            <el-input v-model="info.supervisor.name"></el-input>
          </el-form-item>
          <el-form-item label="监事身份证" class="px-1 w-1/3">
            <el-input v-model="info.supervisor.id"></el-input>
          </el-form-item>
          <el-form-item label="监事地址" class="px-1 w-1/3">
            <el-input v-model="info.supervisor.addr"></el-input>
          </el-form-item>
        </div>
        <div v-for="(invt, i) in info.investor" :key="i" class="flex">
          <el-form-item :label="`股东${i+1}姓名`" class="px-1 w-1/8">
            <el-input v-model="invt.name"></el-input>
          </el-form-item>
          <el-form-item :label="`持股${i+1}比例`" class="px-1 w-1/8">
            <el-input v-model="invt.ratio"></el-input>
          </el-form-item>
          <el-form-item :label="`股东${i+1}证件类型`" class="px-1 w-1/4">
            <el-input v-model="invt.type"></el-input>
          </el-form-item>
          <el-form-item :label="`股东${i+1}份证`" class="px-1 w-1/4">
            <el-input v-model="invt.id"></el-input>
          </el-form-item>
          <el-form-item :label="`股东${i+1}地址`" class="px-1 w-1/4">
            <el-input v-model="invt.addr"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OCR from "@/components/OCR";

export default {
  components: { OCR },
  data() {
    return {
      company: null,
      show_ocr: false,
      buttons1: [
        { label: "克隆", do: this.clone },
        { label: "保存", do: this.save },
        { label: "删除", do: this.delete }
      ],
      buttons2: [
        { label: "名称核准", do: this.approval },
        { label: "核准状态", do: this.approval_status },
        { label: "开业登记", do: this.establishment },
        { label: "开业文档", do: this.doc },
        { label: "OCR", do: () => (this.show_ocr = !this.show_ocr) }
      ],
      ocr_result: null
    };
  },
  computed: {
    ...mapState({
      me: state => state.user.me,
      companys: state => state.fjgs.companys,
      info: state => state.fjgs.info
    }),
    approval_scope() {
      const scope = this.info.approval_scope;
      return scope && scope.map(i => i.split("-")[1]).join(",");
    },
    establishment_scope() {
      const scope = this.info.establishment_scope;
      return scope && scope.map(i => i.split("-")[2]).join(",");
    }
  },
  mounted() {
    this.getCompanys();
  },
  methods: {
    ...mapActions("fjgs", { getCompanys: "getCompanys", getInfo: "getInfo" }),
    select(company) {
      this.getInfo({ company });
    },
    clone() {
      console.log(this.company);
    },
    save() {
      console.log(this.company);
    },
    delete() {
      console.log(this.company);
    },
    approval() {
      console.log(this.company);
    },
    approval_status() {
      console.log(this.company);
    },
    establishment() {
      console.log(this.company);
    },
    doc() {
      console.log(this.company);
    },
    ocr() {
      console.log(this.company);
    }
  }
};
</script>