<template>
    <MTableBoard title="业务内容管理" storeName="service" >
      <template v-slot:other-tool>
        <ServiceFilter />
      </template>

      <template v-slot:table>
        <service-table :border="false"/>
      </template>
    </MTableBoard>
</template>

<script>
import ServiceTable from "./ServiceTable";
import ServiceFilter from "./ServiceFilter";
import MTableBoard from "@/components/MTableBoard"

export default {
  name: "ServiceBoard",
  components: { ServiceTable, MTableBoard, ServiceFilter },
};
</script>