<template>
  <div class="flex items-end">
    <!-- <span class="w-8 text-gray-400">{{year}}</span> -->
    <div class="flex">
      <span v-if="editing">
        <span @click="save" class="text-xs text-indigo-400 pl-1 cursor-pointer">保存</span>
        <span @click="cancel" class="text-xs text-indigo-400 pl-1 cursor-pointer">取消</span>
      </span>
      <span v-else>
        <span @click="edit" class="text-xs text-indigo-400 pl-1 cursor-pointer">填写</span>
        <span @click="del" class="text-xs text-pink-500 pl-1 cursor-pointer">删除</span>
      </span>
    </div>

    <div class="flex">
      <div v-for="(_, i) in agent_fee" :key="i" class="flex flex-col">
        <span v-if="agent_fee.length === 12" class="text-center text-indigo-300">{{i+1}}</span>
        <input
          v-model="agent_fee[i]"
          :disabled="!editing"
          class="ml-1 text-gray-600 border-b border-indigo-300 outline-none text-center"
          :class="{
            'month_width': agent_fee.length === 12,
            'quarter_width': agent_fee.length === 4,
            'half_year_width': agent_fee.length === 2,
            'year_width': agent_fee.length === 1,
          }"
        />
      </div>
    </div>

    <div v-if="uid" class="flex items-center px-2">
      <el-tooltip v-if="company.agent_fee" :content="note" placement="top">
        <el-link :underline="false" @click="editAgentFee">备注</el-link>
      </el-tooltip>
      <el-link v-else :underline="false" @click="editAgentFee">备注</el-link>
      <el-image
        v-if="attachments.length"
        style="width: 20px; height: 20px; margin-left: 5px"
        :src="attachments2urls(is_image(attachments))[0]"
        :preview-src-list="attachments2urls(is_image(attachments))"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import attachmentMixin from "@/mixins/attachment";

export default {
  mixins: [attachmentMixin],
  inject: ["tableBoard"],
  props: {
    company: { type: Object, required: true },
    year: { type: Number, required: true }
  },
  data() {
    return {
      editing: false,
      uid: null,
      note: null,
      attachments: [],
      agent_fee: [],
      origin: []
    };
  },
  watch: {
    year() {
      this.agentFee();
    },
    company() {
      this.agentFee();
    }
  },
  mounted() {
    this.agentFee();
  },
  methods: {
    ...mapActions("agentfee", {
      create: "create",
      update: "update",
      delete: "delete"
    }),
    agentFee() {
      const fees = this.company.agent_fees.filter(i => i.year === this.year);
      if (fees.length > 0) {
        this.uid = fees[0].uid;
        this.attachments = fees[0].attachments;
        this.note = fees[0].note;
        this.agent_fee = [...fees[0].agent_fee];
      } else {
        this.generateAgentFee();
      }
    },
    generateAgentFee() {
      const cycle_count = parseInt(12 / this.company.cycle_months);
      this.agent_fee = [];
      this.editing = false;
      this.uid = null;
      this.note = null;
      this.attachments = [];
      this.origin = [];
      for (let i = 0; i < cycle_count; i++) {
        this.agent_fee.push(0);
      }
    },
    editAgentFee() {
      this.$router.push({
        name: "agentfee-form",
        params: { uid: this.uid }
      });
    },
    edit() {
      this.origin = [...this.agent_fee];
      this.editing = true;
    },
    cancel() {
      this.agent_fee = [...this.origin];
      this.editing = false;
    },
    async del() {
      if (this.uid) {
        this.$confirm(
          `此操作将永久删除【${this.company.name}】${this.year}年收费记录, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(async () => {
            const rlt = await this.delete(this.uid);
            if (rlt) {
              this.generateAgentFee();
            } else {
              this.$notify.error({ message: "删除失败" });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      } else {
        this.generateAgentFee();
      }
    },
    async save() {
      const data = {
        agent_fee: this.agent_fee,
        year: this.year,
        company: this.company.uid,
        consumer: this.company.consumer
      };
      let rlt = false;
      if (this.uid) {
        rlt = await this.update({ uid: this.uid, patch: data });
      } else {
        rlt = await this.create({ data });
      }
      if (rlt) {
        this.$notify.success({ message: "保存成功" });
        this.tableBoard.getData();
        this.editing = false;
      } else {
        this.$notify.error({ message: "保存失败" });
      }
    }
  }
};
</script>

<style scoped>
.month_width {
  width: 32px;
}
.quarter_width {
  width: 104px;
}
.half_year_width {
  width: 212px;
}
.year_width {
  width: 428px;
}
</style>