import { baseUrl } from "./api";
import { select, separator } from "./config";
import app from "./main";

export class Time {
  static today = new Date();
  static year = Time.today.getFullYear();
  static stamp = Time.today.getTime();

  static yearsAgo(years = 1, now = Time.stamp) {
    return now - 3600 * 1000 * 24 * 365 * years;
  }

  static yearRange(year = Time.year) {
    return [
      new Date(`${year}-01-01`).getTime(),
      new Date(`${year}-12-31`).getTime(),
    ];
  }

  static halfYearRange(half = 0, year = Time.year) {
    if (half === 0) {
      return [
        new Date(`${year}-01-01`).getTime(),
        new Date(`${year}-06-30`).getTime(),
      ];
    }
    return [
      new Date(`${year}-07-01`).getTime(),
      new Date(`${year}-12-31`).getTime(),
    ];
  }

  static quarterRange(quarter = 1, year = Time.year) {
    let start, end;
    if (quarter === 1) {
      [start, end] = [`${year}-01-01`, `${year}-03-31`];
    }
    if (quarter === 2) {
      [start, end] = [`${year}-04-01`, `${year}-06-30`];
    }
    if (quarter === 3) {
      [start, end] = [`${year}-07-01`, `${year}-09-30`];
    }
    if (quarter === 4) {
      [start, end] = [`${year}-10-01`, `${year}-12-31`];
    }
    return [new Date(start).getTime(), new Date(end).getTime()];
  }
}

export function popEvent(events, _this) {
  return events.map(function(e) {
    return {
      e: function() {
        return _this.$emit(e);
      },
    };
  });
}

export const filters = {
  time(stamp) {
    return new Date(stamp).toLocaleString();
  },
  date(stamp) {
    return stamp ? new Date(stamp).toLocaleDateString() : "";
  },
  imgUrl(url) {
    return `${baseUrl}${String(url).split("||")[0]}`;
  },
  imgList(url) {
    return String(url)
      .split("||")
      .map((u) => `${baseUrl}${u}`);
  },
  role(permisson) {
    return select.roles[permisson];
  },
};

export function getKeyByValue(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

export function role2permisson(role) {
  return getKeyByValue(select.roles, role);
}

// files(String) => [{source, target}]
export function files2list(files) {
  if (files.length < 1) return [];
  return files.split(separator.file).map((i) => {
    let [target, source] = i.split(separator.uid_file);
    return { source, target };
  });
}

// [{source, target}...] => 'source*targe||...'
export function list2files(list) {
  return list
    .map((i) => `${i.target}${separator.uid_file}${i.source}`)
    .join(separator.file);
}

export function target2url(target) {
  return `${baseUrl}${target}`;
}

export function checkPermisson(permisson, msg = "你不可以这么做") {
  if (permisson <= app.$store.state.user.me.permisson) {
    return true;
  } else {
    app.$notify.warning({ title: "注意", message: msg });
    return false;
  }
}

export function firstUpperCase([first, ...rest]) {
  return first.toUpperCase() + rest.join("");
}

export function getIndexByKeyValue(list, key, value) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] === value) {
      return i;
    }
  }
  return -1;
}

export function getItemByKeyValue(list, key, value) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] === value) {
      return list[i];
    }
  }
  return null;
}
export function tableLevelColor({ row }) {
  if (row.level === 2) {
    return "warning-row";
  }
  if (row.level === 3) {
    return "danger-row";
  }
}

export function arrayToCSV(array, name = "download") {
  /*
  const escape = text =>
    text.replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/,/g, "\\,")
  */

  // const escaped_array = array.map(fields => fields.map(escape))
  const escaped_array = array;
  const csv = escaped_array.map((fields) => fields.join(",")).join("\n");
  const blob = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
