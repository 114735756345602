<template>
    <MTableBoard
      title="员工信息"
      storeName="user"
    >
      <template v-slot:table>
        <user-table />
      </template>
    </MTableBoard>
</template>

<script>
import UserTable from "./UserTable";
import MTableBoard from "@/components/MTableBoard"

export default {
  name: "UserBoard",
  components: { UserTable, MTableBoard },
};
</script>
