import { mapState } from "vuex";
import { firstUpperCase } from '@/helper'

export function tableMixin(storeName) {
  // TODO: 加入权限管理
  return {
    name: `${firstUpperCase(storeName)}Table`,
    props: {
      border: { type: Boolean, default: false },
    },
    inject: ["tableBoard"],
    data() {
      return {
        selected: []
      }
    },
    computed: {
      ...mapState({
        me: state => state.user.me,
        items: state => state[storeName].pagination.items,
        params: state => state[storeName].params
      }),
      tableData() {
        return this.items
      }
    },
    methods: {
      change(row, key) {
        const uid = row.uid;
        let patch = { [key]: row[key] }
        delete patch['uid']
        this.update({ uid, patch })
      },
      changePaidStatus(row, field) {
        const uid = row.uid;
        let patch = {
          [field]: row[field]
        };
        this.$prompt("请输入备注", "是否添加备注", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          inputPattern: /.+/,
          inputErrorMessage: "备注内容不能为空"
        })
          .then(({ value }) => {
            patch[`${field}_note`] = value;
            this.update({ uid, patch });
          })
          .catch(() => {
            this.update({ uid, patch });
          });
      },
      async update(data) {
        this.tableBoard.action(
          () => this.$store.dispatch(`${storeName}/update`, data),
        )
      },
      async deleteItem(uid) {
        this.tableBoard.action(
          () => this.$store.dispatch(`${storeName}/delete`, uid),
          () => { },
          '删除成功',
          '删除失败'
        )
      },
      edit(row, store_name = null) {
        store_name = store_name != null ? store_name : storeName
        this.$router.push({
          name: `${store_name}-form`,
          params: { uid: row.uid }
        })
      },
      batch() {
        this.verifySelected(1)
        this.$router.push({
          name: `${storeName}-form`,
          params: { uid: 'batch' }
        })

      },
      select(val) {
        this.selected = val
        // this.$store.commit(`${storeName}/setPatch`, val.map(e => e.uid))
        this.$store.commit(`${storeName}/setBatch`, val)
      },
      verifySelected(min = 0) {
        if (this.selected.length <= min) {
          const message = `至少选择 ${min+1} 行记录`
          this.$notify.error({ message });
          throw new Error(message)
        }
      },
      async batchTrue(field) {
        this.verifySelected()
        const data = {
          'uid_list': this.selected.map(i => i.uid),
          'patch': { [field]: true }
        }
        this.tableBoard.action(
          () => this.$store.dispatch(`${storeName}/batch_update`, data),
          () => { },
          `成功修改了${data.uid_list.length}条记录`
        )
      },
      // 点击该单元格，则筛选相应field为该值
      async filterCurrent(field, val) {
        this.$store.commit(
          `${storeName}/updateParams`,
          { ...this.params, page: 1, filter: { ...this.params.filter, [field]: val } }
        )
        // 这里还可以优化，这一步可以本地筛选不用给服务器发请求
        this.$parent.getData()
      }
    }

  }
}