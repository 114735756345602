<template>
  <el-select
    :value="value"
    @change="select"
    :placeholder="placeholder"
    :clearable="clearable"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option v-for="s in all" :key="s.uid" :label="s.name" :value="s.uid" />
  </el-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModelSelect",
  props: {
    value: {required: true},
    model: {type: String, required: true},
    placeholder: {type: String, default: '请选择'},
    clearable: {type: Boolean, default: false},
    filterable: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      all: function(store) {return store[this.model].all},
    }),
  },
  /*
  mounted() {
    this.$store.dispatch(`${this.model}/all`)
  },
  */
  methods: {
    select(option) {
      this.$emit('input', option)
    }
  }
}
</script>