<template>
  <div class="flex items-center w-full">
    <ConfigSelect class="w-1/2 pr-1" :value="serviceCategory" @input="changeCategory" config="serviceCategories"/>
    <el-select
      :value="value"
      @change="select"
      filterable
      class="w-1/2 pl-1"
    >
      <el-option v-for="s in services" :key="s.uid" :label="s.name" :value="s.uid" />
    </el-select>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ConfigSelect from "@/components/Select/ConfigSelect";

export default {
  components: {ConfigSelect},
  props: {
    value: {required: true},
    serviceCategory: {type: Number, default: 1},
  },
  computed: {
    ...mapState({
      allServices: state => state.service.all,
    }),
    services() {
      return this.allServices.filter(i => i.category === parseInt(this.serviceCategory))
    }
  },
  mounted() {
    this.getAllServices()
  },
  methods: {
    ...mapActions('service',{getAllServices: 'all'}),
    select(option) {
      this.$emit('input', option)
    },
    changeCategory(category) {
      this.$emit('changeCategory', category)
      this.$emit('input', null)
      // if (this.services.map(i => i.uid).indexOf(this.value) < 0) {
      //   this.$emit('input', null)
      // }
    }
  }
}
</script>

<style>

</style>