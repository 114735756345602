<template>
  <div>
    <el-table :data="tableData" :border="border" :v-loading="true">
      <el-table-column type="index" width="60"></el-table-column>

      <el-table-column prop="name" label="姓名">
        <template slot-scope="scope">
          <el-link :underline="false" @click="edit(scope.row)">{{scope.row.name}}</el-link>
        </template>
      </el-table-column>

      <el-table-column prop="telphone" label="手机"></el-table-column>
      <el-table-column prop="bank_account" label="银行卡号"></el-table-column>

      <el-table-column prop="wx_remark" label="微信"></el-table-column>

      <el-table-column prop="status" label="状态" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :disabled="me.permisson < 10"
            @change="change(scope.row, 'status')"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="companys" label="公司">
        <template slot-scope="scope">
          <el-tooltip
            effect="light"
            :content="scope.row.companys.map(i=>i.name).join(' ')"
            placement="left"
          >
            <span>{{ scope.row.companys.length + " 家"}}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column width="40" v-if="me.permisson >= 1000">
        <template slot-scope="scope">
          <DeleteButton :delete="deleteItem" :uid="scope.row.uid" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DeleteButton from "@/components/DeleteButton";
import { tableMixin } from "@/mixins/table";

export default {
  mixins: [tableMixin("consumer")],
  components: { DeleteButton }
};
</script>