<template>
  <MTableBoard
    title="任务管理"
    storeName="task"
    :permisson="1"
    :showSearch="false"
    :toolbarPermisson="1"
    :onlyMe="true"
  >
    <template v-slot:other-tool>
      <TaskFilter />
    </template>

    <template v-slot:table>
      <task-table :border="false" />
    </template>
  </MTableBoard>
</template>

<script>
import TaskTable from "./TaskTable";
import TaskFilter from "./TaskFilter";
import MTableBoard from "@/components/MTableBoard";

export default {
  name: "TaskBoard",
  components: { TaskTable, MTableBoard, TaskFilter }
};
</script>