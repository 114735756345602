<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="代理人"
    />
    <ModelSelect
      v-model="params.filter.user_invoice"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="开票人"
    />
    <ModelSelect
      v-model="params.filter.consumer"
      @input="tableBoard.filter"
      :clearable="true"
      model="consumer"
      class="pl-1 w-24"
      placeholder="所有人"
    />
    <ConfigSelect
      v-model="params.filter.category"
      @input="tableBoard.filter"
      :clearable="true"
      config="companyCategories"
      class="pl-1 w-24"
      placeholder="类型"
    />
    <ConfigSelect
      v-model="params.filter.status"
      @input="tableBoard.filter"
      :clearable="true"
      config="status"
      class="pl-1 w-20"
      placeholder="状态"
    />
    <ConfigSelect
      v-model="params.filter.acquisition"
      @input="tableBoard.filter"
      :clearable="true"
      config="acquisition"
      class="pl-1 w-24"
      placeholder="获客方式"
    />
    <ModelSelect
      v-model="params.filter.referrer"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="引荐人"
    />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "CompanyFilter",
  mixins: [filterMixin("company")],
  components: { ModelSelect, ConfigSelect },
};
</script>
