<template>
  <m-form v-if="hasEditPermisson" :title="`${mode}报销单`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">

        <el-form-item label="事项(必填)">
          <el-input  v-model="formData.item" />
        </el-form-item>

        <el-form-item label="金额(必填)">
          <el-input type="number" v-model="formData.money" />
        </el-form-item>

        <el-form-item label="发生日期(必填)">
          <el-date-picker
            v-model="formData.date"
            type="date"
            value-format="timestamp"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="备注(非必填，可多行)">
          <textarea v-model="formData.note" class="w-full p-2 font-sm leading-tight border text-gray-600 outline-none"  rows="3"></textarea>
        </el-form-item>

        <div>
          <MAttachment v-model="formData.attachments" />
        </div>

        <el-form-item>
          <button
            @click.prevent="submit"
            class="text-gray-200 bg-indigo-700 px-6 rounded-lg shadow"
          >{{mode}}</button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";
import MAttachment from "@/components/MAttachment";

export default {
  mixins: [formMixin("reimbursement")],
  components: { MForm, MAttachment },
  data() {
    return {
      permisson: 1,
      formData: {
        item: "",
        date: null,
        money: 0,
        note: "",
        attachments: [],
      }
    };
  },
  computed: {
    hasEditPermisson() {
      return this.me.permisson >= this.permisson || this.formData.user === this.me.uid;
    }
  },
  methods: {
    packData() {
      let data = { ...this.formData };
      if (!this.isEdit) {
        data["user"] = this.me.uid;
      }
      return data;
    },
    verify(data) {
      return data.item.length > 0 && data.money > 0 && data.date
    },
  }
};
</script>