<template>
  <div>
    <el-table :data="tableData" :border="border">
      <el-table-column type="index" width="40"></el-table-column>

      <el-table-column prop="name" label="姓名">
        <template slot-scope="scope">
          <el-link :underline="false" @click="edit(scope.row)">{{ scope.row.name }}</el-link>
        </template>
      </el-table-column>

      <el-table-column prop="telphone" label="手机"></el-table-column>
      <el-table-column prop="id_number" label="身份证"></el-table-column>
      <el-table-column width="80" prop="basic_wage" label="基本工资"></el-table-column>
      <el-table-column width="70" prop="full_day_pay" label="满勤奖"></el-table-column>
      <el-table-column width="50" prop="merit_pay" label="补贴"></el-table-column>
      <el-table-column width="70" prop="year_pay" label="工龄奖"></el-table-column>
      <el-table-column width="80" prop="ss_pay" label="代缴社保"></el-table-column>

      <el-table-column prop="join_time" label="入职日期" width="95">
        <template slot-scope="scope">{{ scope.row.join_time | date }}</template>
      </el-table-column>

      <el-table-column prop="leave_time" label="离职日期" width="95">
        <template slot-scope="scope">{{ scope.row.leave_time | date }}</template>
      </el-table-column>

      <el-table-column prop="status" label="在职" width="80">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :disabled="me.permisson < 10"
            @change="change(scope.row, 'status')"></el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="permisson" label="职务">
        <template slot-scope="scope">
          <ConfigSelect v-model="scope.row.permisson" @input="change(scope.row, 'permisson')" config="roles" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { tableMixin } from "@/mixins/table";
import ConfigSelect from "@/components/Select/ConfigSelect";
import { filters } from "@/helper";

export default {
  mixins: [tableMixin("user")],
  components: { ConfigSelect },
  filters: {
    ...filters,
  },
};
</script>