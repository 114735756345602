<template>
  <div>
    <el-table :data="tableData" :border="border" :v-loading="true">
      <el-table-column type="index" width="60"></el-table-column>

      <el-table-column prop="name" label="名称" width="120">
        <template slot-scope="scope">
          <el-link
            v-if="me.permisson >= 1000 || !scope.row.temp_id"
            :underline="false"
            @click="edit(scope.row)"
          >{{scope.row.name}}</el-link>
          <span v-else>{{scope.row.name}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="temp_id" label="模板ID" width="100"></el-table-column>
      <el-table-column prop="content" label="短信内容"></el-table-column>

    </el-table>
  </div>
</template>

<script>
import { tableMixin } from '@/mixins/table'

export default {
  mixins: [tableMixin('smstemplate'), ],
};
</script>