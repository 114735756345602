<template>
  <el-row type="flex" justify="center">
      <el-card shadow="always">页面不知道去哪里了, 再试一试。可能是你没登录</el-card>
  </el-row>
</template>

<script>
export default {

}
</script>

<style>

</style>