<template>
  <div>
    <div class="flex justify-between py-2">
      <div>
      </div>

      <div>
        <span v-for="tool in tools" v-show="me.permisson >= tool.permisson" :key="tool.label" @click="tool.do"
          class="px-1 text-sm text-indigo-500 cursor-pointer">{{ tool.label }}</span>
      </div>
    </div>

    <el-table :data="tableData" :border="border" :v-loading="true" @selection-change="select" sum-text="合" show-summary>
      <el-table-column type="selection" :width="25" label="" align="center" />
      <el-table-column type="index" :width="25" align="center" />

      <el-table-column prop="name" label="年月" width="60" align="center">
        <template slot-scope="scope">
          <span class="flex items-center">
            <!-- <span class="pr-1 text-indigo-400">{{ scope.$index + 1 }}</span> -->
            <el-image v-if="scope.row.attachments &&
              is_image(scope.row.attachments).length > 0
            " style="width: 20px; height: 20px; margin-right: 5px"
              :src="attachments2urls(is_image(scope.row.attachments))[0]" :preview-src-list="attachments2urls(is_image(scope.row.attachments))
                "></el-image>
            <el-link v-if="!scope.row.paid" :underline="false" @click="edit(scope.row)">{{
              `${scope.row.year}-${scope.row.month}` }}</el-link>
            <span v-else>{{ `${scope.row.year}-${scope.row.month}` }}</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="user" label="姓名" width="55" align="center">
        <template slot-scope="scope">
          <span @click="filterCurrent('user', scope.row.user.uid)" class="cursor-pointer">{{ scope.row.user.name
            }}</span>
        </template>
      </el-table-column>

      <el-table-column v-for="h in headers" :key="h.prop" align="center" :prop="h.prop" :label="h.label"
        :width="h.width" />

      <el-table-column prop="paid" label="支付情况" width="80" align="center">
        <template slot-scope="scope">
          <el-switch v-if="!scope.row.paid" :width="30" :disabled="me.permisson < 100"
            :active-text="scope.row.paid ? '已付' : '未付'" v-model="scope.row.paid"
            @change="pay([scope.row.uid])"></el-switch>
          <span v-else>{{
            scope.row.paid_note ? scope.row.paid_note : "已付"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="note" label="备注"></el-table-column>

      <!--
      <el-table-column prop="attachments" label="附件" width="60">
        <template slot-scope="scope">
          <el-link
            v-for="atm in is_other(scope.row.attachments)"
            :key="atm.target"
            :href="targetToUrl(atm.target)"
            :download="atm.name"
          >{{atm.source}}</el-link>
        </template>
      </el-table-column>
      -->
    </el-table>
  </div>
</template>

<script>
import { filters } from "@/helper";
import { tableMixin } from "@/mixins/table";
import attachmentMixin from "@/mixins/attachment";

export default {
  mixins: [tableMixin("wage"), attachmentMixin],
  filters: {
    ...filters,
  },
  inject: ["tableBoard"],
  data() {
    return {
      tools: [
        { permisson: 100, do: this.generate, label: "生成本月工资" },
        { permisson: 100, do: () => this.pay([]), label: "支付" },
      ],
      headers: [
        { prop: "work_days", width: 50, label: "出勤(天)" },
        { prop: "basic", width: 60, label: "基本\n工资" },
        { prop: "full_day_pay", width: 50, label: "全勤奖" },
        { prop: "merit_pay", width: 50, label: "补贴" },
        { prop: "year_pay", width: 50, label: "工龄奖" },
        // { prop: "agent_bonus", width: 50, label: "代理提成" },
        { prop: "agent_bonus", width: 50, label: "记账\n提成" },
        { prop: "invoice_bonus", width: 50, label: "开票\n提成" },
        { prop: "task_bonus", width: 50, label: "任务\n提成" },
        { prop: "introduce_bonus", width: 50, label: "介绍\n提成" },
        { prop: "social_insurance_subsidy", width: 50, label: "社保\n补贴" },
        { prop: "accumulation_fund_subsidy", width: 70, label: "公积金\n补贴" },
        { prop: "heat_subsidy", width: 50, label: "高温\n补贴" },
        { prop: "other_subsidy", width: 50, label: "其他\n补贴" },
        { prop: "gross_salary", width: 60, label: "应发" }, // 20230907 添加
        { prop: "ss_pay", width: 50, label: "代缴\n社保" },
        { prop: "tax_pay", width: 50, label: "代缴\n个税" },
        { prop: "deduction", width: 50, label: "扣款" },
        { prop: "total", width: 60, label: "实发" },
        { prop: "reimbursement", width: 50, label: "报销" },
      ],
    };
  },
  computed: {
    tableData() {
      return this.items.map((i) => {
        return { ...i, gross_salary: this.grossSalary(i), total: this.rowTotal(i) };
      });
    },
  },
  mounted() {
    this.$store.dispatch(`user/all`);
  },
  methods: {
    rowTotal(r) {
      // 2022-02-13 起工资不好含报销，报销单列
      return (
        r.basic +
        r.full_day_pay +
        r.merit_pay +
        r.year_pay +
        r.agent_bonus +
        r.invoice_bonus +
        r.task_bonus +
        r.social_insurance_subsidy +
        // r.accumulation_fund_subsidy +
        // r.reimbursement -
        r.accumulation_fund_subsidy +
        r.other_subsidy -
        r.ss_pay -
        r.tax_pay -
        r.deduction +
        r.introduce_bonus +
        r.heat_subsidy
      );
    },
    grossSalary(r) {
      // 20230907 应发工资
      return (
        r.basic +
        r.full_day_pay +
        r.merit_pay +
        r.year_pay +
        r.agent_bonus +
        r.invoice_bonus +
        r.task_bonus +
        r.social_insurance_subsidy +
        // r.accumulation_fund_subsidy +
        // r.reimbursement -
        r.accumulation_fund_subsidy +
        r.other_subsidy +
        r.introduce_bonus +
        r.heat_subsidy
      );
    },
    async generate() {
      const { year, month } = this.tableBoard.params.filter;
      if (year && month) {
        this.tableBoard.action(
          () => this.$store.dispatch(`wage/generate`, { year, month }),
          () => { },
          `生成${year}年${month}月工资成功`,
          `生成${year}年${month}月工资失败`
        );
      } else {
        this.$notify.error({ message: "年和月份不能为空" });
      }
    },
    async pay(uids = []) {
      if (uids.length === 0) {
        this.verifySelected();
        uids = this.selected.filter((i) => !i.paid).map((i) => i.uid);
      }
      console.log(uids);
      this.tableBoard.action(
        () => this.$store.dispatch(`wage/pay`, uids),
        () => { },
        `成功支付${uids.length}条工资`,
        `支付失败`
      );
    },
  },
};
</script>
