function years() {
  const cYear = new Date().getFullYear() + 1;
  const rlt = {};
  for (let i = 2019; i <= cYear; i++) {
    rlt[i] = `${i}年`;
  }
  return rlt;
}

function months() {
  const rlt = {};
  for (let i = 1; i <= 12; i++) {
    rlt[i] = `${i}月`;
  }
  return rlt;
}

export const select = {
  years: years(),
  months: months(),
  roles: {
    1: "员工",
    10: "组长",
    100: "经理",
    1000: "管理员",
  },

  status: {
    1: "激活",
    0: "冻结",
  },

  levels: {
    1: "一星",
    2: "二星",
    3: "三星",
  },

  companyCategories: {
    1: "个体",
    2: "小规模",
    3: "一般纳税人",
    4: "合作社",
  },

  // 代理费收费周期月数
  cycleMonths: {
    1: "每月",
    3: "每季度",
    6: "每半年",
    12: "每年",
  },

  serviceCategories: {
    1: "工商",
    2: "税务",
    3: "食品",
    4: "银行",
    5: "公安",
    6: "公积金",
    7: "社保",
    8: "卫生局",
    9: "文体局",
    10: "社发局",
    11: "应急局",
    12: "交通局",
    13: "商务局",
    14: "海关",
  },

  receiptCategories: {
    1: "身份证",
    2: "公章",
    3: "营业执照",
    4: "会计凭证",
  },

  taskStatuses: {
    //# 0 未接收 1 进行中 2 已完成
    0: "未接收",
    1: "进行中",
    2: "已完成",
    9: "暂停",
  },

  feeCategories: {
    1: "代理",
    2: "代办",
  },
  feeStatuses: {
    1: "已收",
    0: "未收",
  },
  ocrCategories: {
    id_card: "身份证识别",
    addr_qrcode: "地址二维码",
  },
  acquisition: {
    0: "原有",
    1: "员工引进",
    2: "客户介绍",
    3: "广告",
    4: "客户新注册",
  },
};

export const separator = {
  uid_file: "*",
  file: "||",
};

export const imageSuffix = ["png", "jpg", "jpeg", "gif", "bmp"];
