import Axios from 'axios'
import { User as UserApi } from '../api'
import BaseStore from './base'


export default class User extends BaseStore {
  constructor() {
    super(UserApi, 'user', true)
  }

  state() {
    return {
      ...super.state(),
      me: { name: null, uid: null, permisson: 0, token: localStorage.getItem("token") || "" },
    }
  }

  actions() {
    const api = this.api
    return {
      ...super.actions(),
      async login({ commit }, { name, password }) {
        const res = await api.login(name, password)
        commit('setMe', res.data)
        return res
      },
      async getMe({commit}, token) {
        const res = await api.me()
        commit('setMe', {...res.data, token})
        return res
      },
      logout({ commit }) {
        commit('clearMe')
      }
    }
  }

  mutations() {
    return {
      ...super.mutations(),
      setMe(state, user) {
        state.me = user
        localStorage.setItem('token', user.token)
        Axios.defaults.headers.common['Token'] = user.token
      },
      clearMe(state) {
        state.me = { name: "", uid: "", permisson: 0, token: "" }
        localStorage.removeItem('token')
        delete Axios.defaults.headers.common['Token']
      }
    }
  }
}