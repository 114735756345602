<template>
  <div class="flex">
    <ConfigSelect
      v-model="params.filter.status"
      @input="tableBoard.filter"
      :clearable="true"
      config="status"
      class="pl-1 w-20"
      placeholder="状态"
    />
    <ConfigSelect
      v-model="params.filter.level"
      @input="tableBoard.filter"
      :clearable="true"
      config="levels"
      class="pl-1 w-20"
      placeholder="星级"
    />
    <ConfigSelect
      v-model="params.filter.category"
      @input="tableBoard.filter"
      :clearable="true"
      config="serviceCategories"
      class="pl-1 w-20"
      placeholder="类型"
    />
  </div>
</template>

<script>
import ConfigSelect from "@/components/Select/ConfigSelect";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "CompanyFilter",
  mixins: [filterMixin("service")],
  components: { ConfigSelect }
};
</script>