<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}短信模板`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData">
        <el-form-item label="名称(必填，字数>1)">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="模板ID(该项放空，由管理员填写，一旦填写短信内容就不能再修改)">
          <el-input v-model="formData.temp_id"></el-input>
        </el-form-item>

        <el-form-item label="短信内容(必填，字数>5)">
          <el-input type="textarea" :row="3" v-model="formData.content"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="submit">{{mode}}</el-button>
        </el-form-item>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import formMixin from "@/mixins/form";
import MForm from "@/components/MForm";

export default {
  mixins: [formMixin("smstemplate")],
  components: { MForm },
  data() {
    return {
      permisson: 100,
      formData: {
        name: "",
        temp_id: "",
        content: "",
      }
    };
  },
  methods: {
    verify(data) {
      return data.name.length > 1 && data.content.length > 5;
    }
  }
};
</script>