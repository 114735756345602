<template>
  <div>
    <div
      class="absolute inset-0 flex items-center justify-center bg-gray-100 object-cover object-center"
    ></div>
    <div class="absolute inset-0 flex items-center justify-center">
      <div class="-mt-12 p-10 bg-white rounded border-t-4 border-indigo-400 shadow-lg">
        <h3 class="font-semibold text-xl text-gray-600 text-center pb-4">永信办公系统</h3>
        <input
          v-model="user.name"
          class="block w-64 px-2 py-2 outline-none border-b border-gray-400 placeholder-gray-400 hover:border-indigo-400"
          placeholder="姓名"
          type="text"
        />
        <input
          v-model="user.password"
          @keyup.enter="onSubmit"
          class="block w-64 px-2 py-2 outline-none border-b border-gray-400 placeholder-gray-400 hover:border-indigo-400 mt-2"
          placeholder="密码"
          type="password"
        />
        <div
          @click="onSubmit"
          class="w-full py-2 mt-6 bg-white text-center text-gray-600 font-semibold tracking-widest cursor-pointer outline-none rounded-lg shadow hover:text-indigo-500"
        >登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      user: { name: "", password: "" }
    };
  },
  methods: {
    ...mapActions("user", { login: "login" }),
    async onSubmit() {
      if (!(this.user.name && this.user.password)) {
        this.$notify.warning({ title: "注意", message: "请输入账号和密码" });
        return;
      }
      try {
        await this.login(this.user);
        this.$notify.success({ title: "成功", message: "登录成功" });
        this.$router.push("/");
      } catch {
        this.$notify.error({ title: "失败", message: "账号或密码错误" });
      }
    }
  }
};
</script>

<style>
</style>