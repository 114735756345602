<template>
  <el-date-picker
    v-model="range"
    type="daterange"
    value-format="timestamp"
    align="right"
    unlink-panels
    range-separator="-"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :picker-options="pickerOptions"
    @change="picked"
  ></el-date-picker>
</template>

<script>
import { Time } from "@/helper";
export default {
  name: "MDatePikcer",
  props: {
    shortcut: {
      type: Object,
      default: function() {
        return {
          一季度: Time.quarterRange(1),
          二季度: Time.quarterRange(2),
          三季度: Time.quarterRange(3),
          四季度: Time.quarterRange(4),
          上半年: Time.halfYearRange(0),
          下半年: Time.halfYearRange(1),
          今年: Time.yearRange(),
          去年: Time.yearRange(Time.year - 1)
        };
      }
    }
  },
  data() {
    return {
      range: [],
      pickerOptions: {
        shortcuts: [
          ...Object.entries(this.shortcut).map(function([k, range]) {
            return {
              text: k,
              onClick(p) {
                p.$emit("pick", [...range]);
              }
            };
          })
        ]
      }
    };
  },
  methods: {
    picked(range) {
      if (range) {
        let [start, end] = range
        // end 从00:00:00 改为 23:59:59
        this.$emit("picked", [start, end + (24 * 60 * 60 * 1000) - 1]);
      } else {
        this.$emit("picked", null);
      }
    }
  }
};
</script>