<template>
  <div>
  <table class="table-fixed text-indigo-400">
    <thead>
      <tr>
        <th
          v-for="(column, idx) in columns" :key="idx"
          :class="`w-1/${columns.length}`"
          class="border py-1 font-normal ">{{column.label}}</th>
        <th class="border py-1 font-normal ">
          <div class="w-12">删除</div>
        </th>
      </tr>
    </thead>
    <tbody v-if="value">
      <tr v-for="(row, r) in value" :key="r">
        <td v-for="(col, c) in columns" :key="c" class="border">
          <input
            :type="col.type || 'text'"
            :disabled="col.disabled || false"
            :value="row[col.name]"
            :ref="`${r}_${c}_${col.name}`"
            @blur="change(r, c, col.name)"
            class="w-full py-1 px-2"
          />
        </td>

        <td class="border text-center">
          <span @click="del(r)" class="text-xs text-pink-500 cursor-pointer">删除</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div @click="addRow" class="my-1 py-1 w-full text-center bg-indigo-500 rounded  text-gray-100 cursor-pointer">添加一行</div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {type: Array, required: true},
    value: {type: Array, required: true}
  },
  methods: {
    addRow() {
      this.$emit('input', [ ...this.value, this.newRow()])
    },
    newRow() {
      let row = {}
      this.columns.map((i) => row[i.name] = i.type === 'number' ? 1 : '' )
      return row
    },
    del(idx) {
      let new_value = [...this.value]
      new_value.splice(idx, 1)
      this.$emit('input', new_value)
    },
    change(r, c, column) {
      const val = this.$refs[`${r}_${c}_${column}`][0].value
      let row = this.value[r]
      row[column] = val

      let new_value = [...this.value]
      this.$emit('input', new_value)
    }
  }
};
</script>

<style>
</style>