<template>
  <div>
    <el-table :data="tableData" :border="border" :v-loading="true">
      <el-table-column type="index" width="60"></el-table-column>

      <el-table-column prop="name" label="交接内容">
        <template slot-scope="scope">
          <span class="flex items-center">
            <el-image
              v-if="
                scope.row.attachments &&
                  is_image(scope.row.attachments).length > 0
              "
              style="width: 20px; height: 20px; margin-right: 5px"
              :src="attachments2urls(is_image(scope.row.attachments))[0]"
              :preview-src-list="
                attachments2urls(is_image(scope.row.attachments))
              "
            ></el-image>
            <el-link :underline="false" @click="edit(scope.row)">{{
              scope.row.items.map((i) => i.item).join(",")
            }}</el-link>
            <!-- <span v-else>{{scope.row.items.map(i => i.item).join(',')}}</span> -->
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="company" label="公司">
        <template slot-scope="scope">
          <span
            @click="filterCurrent('company', scope.row.company.uid)"
            class="cursor-pointer"
            >{{ scope.row.company.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column prop="user" label="交接人" width="55">
        <template slot-scope="scope">
          <span
            @click="filterCurrent('user', scope.row.user.uid)"
            class="cursor-pointer"
            >{{ scope.row.user.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column prop="date" label="日期" width="80">
        <template slot-scope="scope">{{ scope.row.date | date }}</template>
      </el-table-column>

      <el-table-column prop="note" label="备注"></el-table-column>

      <el-table-column prop="attachments" label="附件">
        <template slot-scope="scope">
          <el-link
            v-for="atm in is_other(scope.row.attachments)"
            :key="atm.target"
            :href="targetToUrl(atm.target)"
            :download="atm.name"
            >{{ atm.source }}</el-link
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { filters } from "@/helper";
import { tableMixin } from "@/mixins/table";
import attachmentMixin from "@/mixins/attachment";

export default {
  mixins: [tableMixin("receipt"), attachmentMixin],
  filters: {
    ...filters,
  },
  mounted() {
    this.$store.dispatch(`user/all`);
    this.$store.dispatch(`company/all`);
  },
};
</script>
