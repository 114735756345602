<template>
    <MTableBoard
      title="短信模板"
      storeName="smstemplate"
    >
      <template v-slot:table>
        <SMSTemplateTable :border="false" />
      </template>
    </MTableBoard>
</template>

<script>
import SMSTemplateTable from "./SMSTemplateTable";
import MTableBoard from "@/components/MTableBoard"

export default {
  name: "SMSTemplateBoard",
  components: { SMSTemplateTable, MTableBoard },
};
</script>