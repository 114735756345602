<template>
  <m-form v-if="me.permisson >= permisson" :title="`${mode}业务`">
    <template v-slot:form>
      <el-form label-position="top" label-width="80px" :model="formData" class="mt-6">
        <div class="flex">
          <el-form-item label="业务名称" class="w-1/3">
            <el-input v-model="formData.name" />
          </el-form-item>

          <el-form-item label="类别" class="w-1/3 px-2">
            <ConfigSelect v-model="formData.category" config="serviceCategories"/>
          </el-form-item>

          <!--
          <el-form-item label="星级" class="w-1/3">
            <ConfigSelect v-model="formData.level" config="levels"/>
          </el-form-item>
          -->
          <el-form-item label="短信模板" class="w-1/3">
            <ModelSelect v-model="formData.sms_template" model="smstemplate"/>
          </el-form-item>
        </div>

        <el-form-item label="描述" class="mt-2 pb-3 border-t border-b border-indigo-200">
          <el-input type="textarea" :row="3" v-model="formData.description"></el-input>
        </el-form-item>

        <div class="flex">
          <el-form-item label="收费(添加后不能修改)" class="w-1/3">
            <!-- <el-input :disabled="isEdit" v-model="formData.charge" /> -->
            <el-input v-model="formData.charge" />
          </el-form-item>

          <el-form-item label="提成(添加后不能修改)" class="w-1/3 px-2">
            <!-- <el-input :disabled="isEdit" v-model="formData.bonus" /> -->
            <el-input v-model="formData.bonus" />
          </el-form-item>

          <el-form-item label="办理时长" class="w-1/3">
            <el-input v-model="formData.days" placeholder="工作日"></el-input>
          </el-form-item>
        </div>

        <MAttachment v-model="formData.attachments"/>

        <div class="flex justify-start mt-4">
          <m-button @click="submit" :label="mode" class="px-4"/>
        </div>
      </el-form>
    </template>
  </m-form>
</template>

<script>
import MButton from "@/components/MButton";
import MForm from "@/components/MForm";
import ConfigSelect from "@/components/Select/ConfigSelect";
import ModelSelect from "@/components/Select/ModelSelect";
import formMixin from "@/mixins/form";
import MAttachment from "@/components/MAttachment";

export default {
  components: { MForm, MButton, ConfigSelect, MAttachment, ModelSelect },
  mixins: [formMixin("service")],
  data() {
    return {
      permisson: 100,
      formData: {
        name: "",
        level: 1,
        category: 1,
        sms_template: null,
        days: 0,
        charge: 0,
        bonus: 0,
        description: "",
        attachments: []
      },
    };
  },
  methods: {
    packData() {
      let data = {...this.formData}
      /*
      if (this.isEdit) {
        delete data['bonus']
        delete data['charge']
      }
      */
      return data
    },
    verify(data) {
      return [data.name, data.description].every(i => i.length > 1);
    },
  }
};
</script>