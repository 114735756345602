<template>
  <MTableBoard title="客户信息" storeName="consumer">
    <template v-slot:table>
      <consumer-table :border="false" />
    </template>
  </MTableBoard>
</template>

<script>
import ConsumerTable from "./ConsumerTable";
import MTableBoard from "@/components/MTableBoard";

export default {
  name: "ConsumerBoard",
  components: { ConsumerTable, MTableBoard }
};
</script>