<template>
  <div class="flex">
    <ModelSelect
      v-model="params.filter.user"
      @input="tableBoard.filter"
      :clearable="true"
      model="user"
      class="pl-1 w-24"
      placeholder="交接人"
    />
    <ModelSelect
      v-model="params.filter.company"
      @input="tableBoard.filter"
      :clearable="true"
      model="company"
      class="pl-1"
      placeholder="公司"
    />
    <MDatePicker @picked="tableBoard.selectRange" />
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import MDatePicker from "@/components/MDatePicker";
import { filterMixin } from "@/mixins/filter";

export default {
  name: "ReceiptFilter",
  mixins: [filterMixin("receipt")],
  components: { ModelSelect, MDatePicker }
};
</script>